import useSearchParam from "@hooks/useSearchParam";
import { Cancel, Save } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { mutationErrorHandler } from "@services/relay/utils";
import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { graphql, useMutation } from "react-relay";

import LocationSelect from "./LocationSelect";
import { type LocationsListItemAddMutation } from "./__generated__/LocationsListItemAddMutation.graphql";

type Props = {
  hideForm: () => void;
};

export type BrandLocationInputs = {
  place: google.maps.places.AutocompletePrediction | null;
  coords?: [number, number];
};

const mutation = graphql`
  mutation LocationsListItemAddMutation($input: BrandLocationUpsertInput!) {
    brandLocationUpsert(input: $input) {
      brand {
        ...LocationsList_brand
      }
      brandLocation {
        id
      }
    }
  }
`;

const LocationsListItemAdd: FC<Props> = ({ hideForm }) => {
  const [commitMutation, mutationLoading] = useMutation<LocationsListItemAddMutation>(mutation);
  const [, setRawPoint] = useSearchParam("rawPoint");
  const [, setLocationId] = useSearchParam("locationId");

  const methods = useForm<BrandLocationInputs>({
    defaultValues: {
      place: null,
    },
  });

  const coords = methods.watch("coords");

  const onClose = (): void => {
    setRawPoint(null);
    hideForm();
  };

  const onSubmit = (data: BrandLocationInputs): void => {
    if (data.place == null) {
      throw new Error("No place selected unexpectedly");
    } else if (data.coords == null) {
      throw new Error("No coordinates selected unexpectedly");
    }

    commitMutation({
      variables: {
        input: {
          address: data.place.description,
          googlePlaceId: data.place.place_id,
          point: {
            type: "POINT",
            coordinates: data.coords,
          },
        },
      },
      onCompleted: (response) => {
        setRawPoint(null);
        if (response.brandLocationUpsert != null) {
          setLocationId(response.brandLocationUpsert.brandLocation.id);
        }
        hideForm();
      },
      onError: mutationErrorHandler,
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          const wrappedOnSubmit = methods.handleSubmit(onSubmit);
          void wrappedOnSubmit(e);
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <LocationSelect />

          <Stack direction="row" spacing={1}>
            <IconButton onClick={onClose}>
              <Cancel />
            </IconButton>
            <IconButton type="submit" disabled={coords == null || mutationLoading}>
              <Save color={coords == null || mutationLoading ? "disabled" : "success"} />
            </IconButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default LocationsListItemAdd;
