/**
 * @generated SignedSource<<84da6b78a9c6d3940fba9ce14f00862c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentLibraryQuery$variables = {
  offer?: string | null | undefined;
  ordering?: string | null | undefined;
  publishedSince?: any | null | undefined;
  publishedUntil?: any | null | undefined;
};
export type ContentLibraryQuery$data = {
  readonly viewerBrand: {
    readonly " $fragmentSpreads": FragmentRefs<"MediaListViewerBrandFragment">;
  };
};
export type ContentLibraryQuery = {
  response: ContentLibraryQuery$data;
  variables: ContentLibraryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offer"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ordering"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "publishedSince"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "publishedUntil"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 15
  },
  {
    "kind": "Variable",
    "name": "offer",
    "variableName": "offer"
  },
  {
    "kind": "Variable",
    "name": "ordering",
    "variableName": "ordering"
  },
  {
    "kind": "Variable",
    "name": "publishedSince",
    "variableName": "publishedSince"
  },
  {
    "kind": "Variable",
    "name": "publishedUntil",
    "variableName": "publishedUntil"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentLibraryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerBrand",
        "kind": "LinkedField",
        "name": "viewerBrand",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MediaListViewerBrandFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContentLibraryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerBrand",
        "kind": "LinkedField",
        "name": "viewerBrand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandType",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "InstagramMediaTypeConnection",
                "kind": "LinkedField",
                "name": "instagramMedias",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstagramMediaTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstagramMediaType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "caption",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mediaId",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "permalink",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "publishedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OfferPeriodAcceptanceType",
                            "kind": "LinkedField",
                            "name": "offerPeriodAcceptance",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OfferPeriodType",
                                "kind": "LinkedField",
                                "name": "offerPeriod",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "shortCode",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InstagramMediaType",
                            "kind": "LinkedField",
                            "name": "medias",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [
                  "ordering",
                  "offer",
                  "publishedSince",
                  "publishedUntil"
                ],
                "handle": "connection",
                "key": "MediaListViewerBrandFragment_instagramMedias",
                "kind": "LinkedHandle",
                "name": "instagramMedias"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "66cf7fdc7d7f98ae0963e07455a78491",
    "id": null,
    "metadata": {},
    "name": "ContentLibraryQuery",
    "operationKind": "query",
    "text": "query ContentLibraryQuery(\n  $ordering: String\n  $offer: String\n  $publishedSince: Date\n  $publishedUntil: Date\n) {\n  viewerBrand {\n    ...MediaListViewerBrandFragment\n    id\n  }\n}\n\nfragment MediaListCardImage_instagramMedia on InstagramMediaType {\n  id\n  mediaUrl\n}\n\nfragment MediaListCardVideo_instagramMedia on InstagramMediaType {\n  id\n  mediaUrl\n  thumbnailUrl\n}\n\nfragment MediaListCard_instagramMedia on InstagramMediaType {\n  id\n  caption\n  mediaId\n  mediaUrl\n  mediaType\n  permalink\n  publishedAt\n  offerPeriodAcceptance {\n    id\n    offerPeriod {\n      id\n      shortCode\n    }\n  }\n  medias {\n    id\n    mediaUrl\n  }\n  ...MediaListCardVideo_instagramMedia\n  ...MediaListCardImage_instagramMedia\n  ...MediaListCarousel_instagramMedia\n}\n\nfragment MediaListCarousel_instagramMedia on InstagramMediaType {\n  id\n  medias {\n    id\n    mediaType\n    ...MediaListCardVideo_instagramMedia\n    ...MediaListCardImage_instagramMedia\n  }\n}\n\nfragment MediaListViewerBrandFragment on ViewerBrand {\n  brand {\n    id\n    instagramMedias(ordering: $ordering, offer: $offer, publishedSince: $publishedSince, publishedUntil: $publishedUntil, first: 15) {\n      edges {\n        node {\n          id\n          ...MediaListCard_instagramMedia\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "b6a2b75bcd78163b38d9778d495617b0";

export default node;
