import { Box, Skeleton } from "@mui/material";
import { type FC } from "react";

const Loader: FC = () => {
  return (
    <Box m={2}>
      <Skeleton variant="rounded" width={"100%"} height={200} />
    </Box>
  );
};

export default Loader;
