import useSearchParam from "@hooks/useSearchParam";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from "@mui/material";
import { useEffect, type FC } from "react";

const options = [
  { label: "Newest", value: "-publishedAt" },
  { label: "Oldest", value: "publishedAt" },
];

const Ordering: FC = () => {
  const [ordering, setOrdering] = useSearchParam("ordering");

  useEffect(() => {
    if (ordering !== "-publishedAt" && ordering !== "publishedAt") {
      setOrdering("-publishedAt");
    }
  }, [ordering, setOrdering]);

  const handleChange = (event: SelectChangeEvent): void => {
    const newOrdering = event.target.value;
    setOrdering(newOrdering);
  };

  return (
    <Box width={{ md: 200 }}>
      <FormControl fullWidth>
        <InputLabel id="ordering-select-label">Ordering</InputLabel>
        <Select
          labelId="ordering-select-label"
          id="ordering-select"
          value={ordering ?? ""}
          label="Ordering"
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Ordering;
