import { type FC } from "react";
import { graphql, useFragment } from "react-relay";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import MediaListCardImage from "./MediaListCardImage";
import MediaListCardVideo from "./MediaListCardVideo";
import { type MediaListCarousel_instagramMedia$key } from "./__generated__/MediaListCarousel_instagramMedia.graphql";

type Props = {
  instagramMediaRef: MediaListCarousel_instagramMedia$key;
};

const instagramMediaFragment = graphql`
  fragment MediaListCarousel_instagramMedia on InstagramMediaType {
    id
    medias {
      id
      mediaType
      ...MediaListCardVideo_instagramMedia
      ...MediaListCardImage_instagramMedia
    }
  }
`;

const MediaListCarousel: FC<Props> = ({ instagramMediaRef }) => {
  const media = useFragment(instagramMediaFragment, instagramMediaRef);

  return (
    <Slider dots infinite={false}>
      {media.medias.map((media) =>
        media.mediaType === "VIDEO" ? (
          <MediaListCardVideo key={media.id} instagramMediaRef={media} />
        ) : (
          <MediaListCardImage key={media.id} instagramMediaRef={media} />
        )
      )}
    </Slider>
  );
};

export default MediaListCarousel;
