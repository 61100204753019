import logoWhiteUrl from "@assets/logo-white.png";
import useSearchParam from "@hooks/useSearchParam";
import {
  Alert,
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Toolbar,
} from "@mui/material";
import { mutationErrorHandler } from "@services/relay/utils";
import { useState, type FC } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { graphql, useMutation } from "react-relay";

import { type LoginMutation } from "./__generated__/LoginMutation.graphql";

type Inputs = {
  email: string;
};

const mutation = graphql`
  mutation LoginMutation($input: BrandLoginInput!) {
    brandLogin(input: $input) {
      ok
    }
  }
`;

const Login: FC = () => {
  const [next] = useSearchParam("next");
  const [commitMutation, mutationLoading] = useMutation<LoginMutation>(mutation);
  const [emailSent, setEmailSent] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    commitMutation({
      variables: { input: { email: data.email, next } },
      onCompleted: () => {
        setEmailSent(true);
      },
      onError: mutationErrorHandler,
    });
  };

  return (
    <Box>
      <AppBar position="static" sx={{ px: 2 }}>
        <Toolbar disableGutters>
          <img src={logoWhiteUrl} alt="logo" height={33} width={160} />
        </Toolbar>
      </AppBar>

      <Container>
        <Box sx={{ pt: "30vh", display: "flex", justifyContent: "center" }}>
          <form
            onSubmit={(e) => {
              const wrappedOnSubmit = handleSubmit(onSubmit);
              void wrappedOnSubmit(e);
            }}
          >
            <Stack gap={1} minWidth={{ sm: 300, md: 450 }}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                error={errors.email != null}
                helperText={errors.email != null && "Email is required"}
                aria-errormessage="email is required"
                inputProps={{ ...register("email", { required: true }) }}
                type="email"
              />

              <Button variant="contained" type="submit" disabled={mutationLoading || emailSent}>
                <Stack direction="row" gap={1}>
                  Login
                  {mutationLoading && <CircularProgress size={20} />}
                </Stack>
              </Button>

              {emailSent && (
                <Alert severity="success">
                  We have emailed a sign-in link. Please check your mailbox.
                </Alert>
              )}
            </Stack>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
