import useSearchParam from "@hooks/useSearchParam";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import type React from "react";
import { useEffect, type FC } from "react";

const PublishedUntil: FC = () => {
  const [publishedUntil, setPublishedUntil] = useSearchParam("publishedUntil");

  useEffect(() => {
    if (publishedUntil !== null && !dayjs(publishedUntil).isValid()) {
      setPublishedUntil(null);
    }
  }, [publishedUntil, setPublishedUntil]);

  const onChange: React.ComponentProps<typeof DatePicker>["onChange"] = (
    newValue,
    context
  ): void => {
    if (context.validationError != null) return;

    if (newValue === null) {
      setPublishedUntil(null);
    } else {
      setPublishedUntil(newValue.format("YYYY-MM-DD"));
    }
  };

  return (
    <DatePicker
      label="Published until"
      disableFuture
      value={publishedUntil !== null ? dayjs(publishedUntil) : null}
      onChange={onChange}
      slotProps={{
        actionBar: {
          actions: ["clear", "cancel", "accept"],
        },
      }}
    />
  );
};

export default PublishedUntil;
