import { Add } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { nodes } from "@services/relay/utils";
import { useState, type FC } from "react";
import { graphql, useFragment } from "react-relay";

import LocationsListItem from "./LocationsListItem";
import LocationsListItemAdd from "./LocationsListItemAdd";
import { type LocationsList_brand$key } from "./__generated__/LocationsList_brand.graphql";

type Props = {
  brandRef: LocationsList_brand$key;
};

const brandFragment = graphql`
  fragment LocationsList_brand on BrandType {
    id
    locations {
      edges {
        node {
          id
          ...LocationsListItem_location
        }
      }
    }
  }
`;

const LocationsList: FC<Props> = ({ brandRef }) => {
  const brand = useFragment(brandFragment, brandRef);
  const [isShowingForm, setIsShowingForm] = useState(false);
  const locations = nodes(brand.locations);

  const showForm = (): void => {
    setIsShowingForm(true);
  };

  const hideForm = (): void => {
    setIsShowingForm(false);
  };

  return (
    <>
      <Typography variant="h6">Addresses:</Typography>
      <Stack spacing={1}>
        {locations.map((location) => (
          <LocationsListItem key={location.id} locationRef={location} />
        ))}

        {isShowingForm ? (
          <LocationsListItemAdd hideForm={hideForm} />
        ) : (
          <Button variant="contained" sx={{ width: 100 }} startIcon={<Add />} onClick={showForm}>
            Add
          </Button>
        )}
      </Stack>
    </>
  );
};

export default LocationsList;
