/**
 * @generated SignedSource<<d4a7dc8e07286df7d7a620140aeecd78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MediaListInstagramMediasPaginationQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  id: string;
  offer?: string | null | undefined;
  ordering?: string | null | undefined;
  publishedSince?: any | null | undefined;
  publishedUntil?: any | null | undefined;
};
export type MediaListInstagramMediasPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"MediaListViewerBrandFragment">;
  } | null | undefined;
};
export type MediaListInstagramMediasPaginationQuery = {
  response: MediaListInstagramMediasPaginationQuery$data;
  variables: MediaListInstagramMediasPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 15,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offer"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ordering"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "publishedSince"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "publishedUntil"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "offer",
    "variableName": "offer"
  },
  {
    "kind": "Variable",
    "name": "ordering",
    "variableName": "ordering"
  },
  {
    "kind": "Variable",
    "name": "publishedSince",
    "variableName": "publishedSince"
  },
  {
    "kind": "Variable",
    "name": "publishedUntil",
    "variableName": "publishedUntil"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaUrl",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MediaListInstagramMediasPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "MediaListViewerBrandFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "MediaListInstagramMediasPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandType",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "InstagramMediaTypeConnection",
                    "kind": "LinkedField",
                    "name": "instagramMedias",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstagramMediaTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InstagramMediaType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "caption",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mediaId",
                                "storageKey": null
                              },
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "permalink",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "publishedAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OfferPeriodAcceptanceType",
                                "kind": "LinkedField",
                                "name": "offerPeriodAcceptance",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OfferPeriodType",
                                    "kind": "LinkedField",
                                    "name": "offerPeriod",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "shortCode",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "InstagramMediaType",
                                "kind": "LinkedField",
                                "name": "medias",
                                "plural": true,
                                "selections": [
                                  (v9/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "filters": [
                      "ordering",
                      "offer",
                      "publishedSince",
                      "publishedUntil"
                    ],
                    "handle": "connection",
                    "key": "MediaListViewerBrandFragment_instagramMedias",
                    "kind": "LinkedHandle",
                    "name": "instagramMedias"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ViewerBrand",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38b4c01eade253c5688e397ada7e1402",
    "id": null,
    "metadata": {},
    "name": "MediaListInstagramMediasPaginationQuery",
    "operationKind": "query",
    "text": "query MediaListInstagramMediasPaginationQuery(\n  $count: Int = 15\n  $cursor: String\n  $offer: String\n  $ordering: String\n  $publishedSince: Date\n  $publishedUntil: Date\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...MediaListViewerBrandFragment_1G22uz\n    id\n  }\n}\n\nfragment MediaListCardImage_instagramMedia on InstagramMediaType {\n  id\n  mediaUrl\n}\n\nfragment MediaListCardVideo_instagramMedia on InstagramMediaType {\n  id\n  mediaUrl\n  thumbnailUrl\n}\n\nfragment MediaListCard_instagramMedia on InstagramMediaType {\n  id\n  caption\n  mediaId\n  mediaUrl\n  mediaType\n  permalink\n  publishedAt\n  offerPeriodAcceptance {\n    id\n    offerPeriod {\n      id\n      shortCode\n    }\n  }\n  medias {\n    id\n    mediaUrl\n  }\n  ...MediaListCardVideo_instagramMedia\n  ...MediaListCardImage_instagramMedia\n  ...MediaListCarousel_instagramMedia\n}\n\nfragment MediaListCarousel_instagramMedia on InstagramMediaType {\n  id\n  medias {\n    id\n    mediaType\n    ...MediaListCardVideo_instagramMedia\n    ...MediaListCardImage_instagramMedia\n  }\n}\n\nfragment MediaListViewerBrandFragment_1G22uz on ViewerBrand {\n  brand {\n    id\n    instagramMedias(ordering: $ordering, offer: $offer, publishedSince: $publishedSince, publishedUntil: $publishedUntil, after: $cursor, first: $count) {\n      edges {\n        node {\n          id\n          ...MediaListCard_instagramMedia\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "ab59ea1c93654fbc56be8ecbb4124de5";

export default node;
