import { Button, Stack, Typography } from "@mui/material";
import { type FC } from "react";
import { graphql, useFragment } from "react-relay";

import {
  type ShowBrandInfo_brand$data,
  type ShowBrandInfo_brand$key,
} from "./__generated__/ShowBrandInfo_brand.graphql";

type Props = {
  brandRef: ShowBrandInfo_brand$key;
  onEdit: () => void;
};

const brandFragment = graphql`
  fragment ShowBrandInfo_brand on BrandType {
    id
    name
    igName
    phone
    website
    category {
      id
      name
      icon
    }
    businessPageYelp
    businessPageGoogle
  }
`;

const fields: Array<{ label: string; key: keyof ShowBrandInfo_brand$data }> = [
  { label: "Name", key: "name" },
  { label: "Instagram Name", key: "igName" },
  { label: "Phone", key: "phone" },
  { label: "Website", key: "website" },
  { label: "Category", key: "category" },
  { label: "Yelp page url", key: "businessPageYelp" },
  { label: "Google page url", key: "businessPageGoogle" },
];

const ShowBrandInfo: FC<Props> = ({ brandRef, onEdit }) => {
  const brand = useFragment(brandFragment, brandRef);

  return (
    <Stack spacing={2} width="100%">
      {fields.map(({ key, label }) => (
        <Stack
          key={key}
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Typography flex={1}>{label}:</Typography>
          {key === "category" ? (
            <Stack flex={2} direction="row" spacing={1} alignItems="center">
              <img src={brand.category?.icon} />
              <Typography fontWeight={700}>{brand.category?.name}</Typography>
            </Stack>
          ) : (
            <Typography flex={2} fontWeight={700} sx={{ overflowWrap: "anywhere" }}>
              {brand[key]}
            </Typography>
          )}
        </Stack>
      ))}

      <Button variant="contained" type="button" onClick={onEdit} fullWidth>
        Edit
      </Button>
    </Stack>
  );
};

export default ShowBrandInfo;
