import useSearchParam from "@hooks/useSearchParam";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import type React from "react";
import { useEffect, type FC } from "react";

const PublishedSince: FC = () => {
  const [publishedSince, setPublishedSince] = useSearchParam("publishedSince");

  useEffect(() => {
    if (publishedSince !== null && !dayjs(publishedSince).isValid()) {
      setPublishedSince(null);
    }
  }, [publishedSince, setPublishedSince]);

  const onChange: React.ComponentProps<typeof DatePicker>["onChange"] = (
    newValue,
    context
  ): void => {
    if (context.validationError != null) return;

    if (newValue === null) {
      setPublishedSince(null);
    } else {
      setPublishedSince(newValue.format("YYYY-MM-DD"));
    }
  };

  return (
    <DatePicker
      label="Published since"
      disableFuture
      value={publishedSince !== null ? dayjs(publishedSince) : null}
      onChange={onChange}
      slotProps={{
        actionBar: {
          actions: ["clear", "cancel", "accept"],
        },
      }}
    />
  );
};

export default PublishedSince;
