import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, CircularProgress, InputAdornment, Stack, TextField } from "@mui/material";
import { mutationErrorHandler } from "@services/relay/utils";
import { type FC } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { graphql, useFragment, useMutation } from "react-relay";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import * as z from "zod";

import CategorySelect from "./CategorySelect";
import { type EditBrandInfoMutation } from "./__generated__/EditBrandInfoMutation.graphql";
import { type EditBrandInfo_brand$key } from "./__generated__/EditBrandInfo_brand.graphql";
import { type EditBrandInfo_viewer$key } from "./__generated__/EditBrandInfo_viewer.graphql";

type Props = {
  viewerRef: EditBrandInfo_viewer$key;
  onClose: () => void;
};

const schema = z.object({
  name: z.string().min(1),
  igName: z.string().min(1),
  phone: z.string().refine((val) => isMobilePhone(val)),
  website: z.string().url(),
  category: z.string(),
  businessPageYelp: z.string().url(),
  businessPageGoogle: z.string().url(),
});

export type Schema = z.infer<typeof schema>;

const brandFragment = graphql`
  fragment EditBrandInfo_brand on BrandType {
    id
    name
    igName
    phone
    website
    category {
      id
      name
    }
    businessPageYelp
    businessPageGoogle
  }
`;

const viewerFragment = graphql`
  fragment EditBrandInfo_viewer on ViewerBrand {
    brand {
      id
      ...EditBrandInfo_brand
    }
    ...CategorySelect_viewer
  }
`;

const mutation = graphql`
  mutation EditBrandInfoMutation($input: BrandUpdateInput!) {
    brandUpdate(input: $input) {
      brand {
        ...EditBrandInfo_brand
      }
    }
  }
`;

const EditBrandInfo: FC<Props> = ({ viewerRef, onClose }) => {
  const viewer = useFragment(viewerFragment, viewerRef);
  const brand = useFragment(brandFragment, viewer.brand as EditBrandInfo_brand$key);
  const [commitMutation, mutationLoading] = useMutation<EditBrandInfoMutation>(mutation);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: brand.name,
      igName: brand.igName ?? "",
      phone: brand.phone ?? "",
      website: brand.website ?? "",
      category: brand.category?.id ?? "",
      businessPageYelp: brand.businessPageYelp ?? "",
      businessPageGoogle: brand.businessPageGoogle ?? "",
    },
  });

  const onSubmit: SubmitHandler<Schema> = (data) => {
    commitMutation({
      variables: { input: data },
      onCompleted: onClose,
      onError: mutationErrorHandler,
    });
  };

  return (
    <Box flex={1}>
      <form
        onSubmit={(e) => {
          const wrappedOnSubmit = handleSubmit(onSubmit);
          void wrappedOnSubmit(e);
        }}
      >
        <Stack spacing={2}>
          <TextField
            id="name"
            size="small"
            label="Name"
            variant="outlined"
            error={errors.name != null}
            inputProps={{ ...register("name", { required: true }) }}
            type="text"
          />

          <TextField
            id="igName"
            size="small"
            label="Instagram Name"
            variant="outlined"
            error={errors.igName != null}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ margin: 0 }} position="start">
                  @
                </InputAdornment>
              ),
              ...register("igName", { required: true }),
            }}
            type="text"
          />

          <TextField
            id="phone"
            size="small"
            label="Phone"
            variant="outlined"
            error={errors.phone != null}
            inputProps={{ ...register("phone") }}
            type="tel"
          />

          <TextField
            id="website"
            size="small"
            label="Website"
            variant="outlined"
            error={errors.website != null}
            inputProps={{ ...register("website") }}
          />

          <CategorySelect viewerRef={viewer} control={control} />

          <TextField
            id="yelp-page"
            size="small"
            label="Yelp page url"
            variant="outlined"
            error={errors.businessPageYelp != null}
            inputProps={{ ...register("businessPageYelp") }}
          />

          <TextField
            size="small"
            id="google-page"
            label="Google page url"
            variant="outlined"
            error={errors.businessPageGoogle != null}
            inputProps={{ ...register("businessPageGoogle") }}
          />

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1} alignItems="center">
              <Button variant="contained" color="success" type="submit" disabled={mutationLoading}>
                Save
              </Button>
              {mutationLoading && <CircularProgress size={25} />}
            </Stack>
            <Button
              variant="contained"
              type="button"
              color="info"
              disabled={mutationLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};

export default EditBrandInfo;
