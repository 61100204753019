/**
 * @generated SignedSource<<cbc6ef85c51f1c46a75e9219e9a7a112>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocationsListItem_location$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"LocationsListItemDisplay_location" | "LocationsListItemEdit_location">;
  readonly " $fragmentType": "LocationsListItem_location";
};
export type LocationsListItem_location$key = {
  readonly " $data"?: LocationsListItem_location$data;
  readonly " $fragmentSpreads": FragmentRefs<"LocationsListItem_location">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LocationsListItem_location",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LocationsListItemDisplay_location"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LocationsListItemEdit_location"
    }
  ],
  "type": "BrandLocationType",
  "abstractKey": null
};

(node as any).hash = "629ac13d7ce490c8110f5cf1644cf133";

export default node;
