/**
 * @generated SignedSource<<1c30fb6049e4e1e3080ea8cf84ea71ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserBrandRegistrationConfirmInput = {
  approveCode: string;
  clientMutationId?: string | null | undefined;
  formId: string;
};
export type RegistrationConfirmMutation$variables = {
  input: UserBrandRegistrationConfirmInput;
};
export type RegistrationConfirmMutation$data = {
  readonly userBrandRegistrationConfirm: {
    readonly ok: boolean;
  } | null | undefined;
};
export type RegistrationConfirmMutation = {
  response: RegistrationConfirmMutation$data;
  variables: RegistrationConfirmMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserBrandRegistrationConfirmPayload",
    "kind": "LinkedField",
    "name": "userBrandRegistrationConfirm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegistrationConfirmMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegistrationConfirmMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fe3a7d182ee131d690d21de9594b7e12",
    "id": null,
    "metadata": {},
    "name": "RegistrationConfirmMutation",
    "operationKind": "mutation",
    "text": "mutation RegistrationConfirmMutation(\n  $input: UserBrandRegistrationConfirmInput!\n) {\n  userBrandRegistrationConfirm(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "1aca02dd6fe51106b7a9db7f91bbb7c5";

export default node;
