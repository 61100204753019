import { Box } from "@mui/material";
import { type FC, type ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const Device: FC<Props> = ({ children }) => {
  return (
    <Box sx={{ scale: { xs: "0.8", sm: "1" } }}>
      <div className="marvel-device iphone-x">
        <div className="notch">
          <div className="camera"></div>
          <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
          <div className="shadow shadow--tr"></div>
          <div className="shadow shadow--tl"></div>
          <div className="shadow shadow--br"></div>
          <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className="screen">{children}</div>
      </div>
    </Box>
  );
};

export default Device;
