/**
 * @generated SignedSource<<281ebfa78840ffde708123c510371dd3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategorySelect_viewer$data = {
  readonly categories: ReadonlyArray<{
    readonly icon: string;
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "CategorySelect_viewer";
};
export type CategorySelect_viewer$key = {
  readonly " $data"?: CategorySelect_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategorySelect_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategorySelect_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CategoryType",
      "kind": "LinkedField",
      "name": "categories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerBrand",
  "abstractKey": null
};

(node as any).hash = "66cf4a1676c23eaf8c74a27951022fcf";

export default node;
