import logoWhiteUrl from "@assets/logo-white.png";
import {
  Alert,
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { mutationErrorHandler } from "@services/relay/utils";
import { useState, type FC } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { graphql, useLazyLoadQuery, useMutation } from "react-relay";
import { useParams } from "wouter";

import { type RegistrationMutation } from "./__generated__/RegistrationMutation.graphql";
import { type RegistrationQuery } from "./__generated__/RegistrationQuery.graphql";

type Inputs = {
  email: string;
};

const query = graphql`
  query RegistrationQuery($id: ID!) {
    userBrandRegistrationForm(id: $id) {
      id
      brand {
        id
        name
      }
    }
  }
`;

const mutation = graphql`
  mutation RegistrationMutation($input: UserBrandRegistrationRequestInput!) {
    userBrandRegistrationRequest(input: $input) {
      ok
    }
  }
`;

const Registration: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [commitMutation, mutationLoading] = useMutation<RegistrationMutation>(mutation);
  const [emailSent, setEmailSent] = useState(false);
  const data = useLazyLoadQuery<RegistrationQuery>(
    query,
    { id },
    { fetchPolicy: "store-and-network" }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    commitMutation({
      variables: { input: { email: data.email, formId: id } },
      onCompleted: () => {
        setEmailSent(true);
      },
      onError: mutationErrorHandler,
    });
  };

  return (
    <Box>
      <AppBar position="static" sx={{ px: 2 }}>
        <Toolbar disableGutters>
          <img src={logoWhiteUrl} alt="logo" height={33} width={160} />
        </Toolbar>
      </AppBar>

      <Container>
        <Box sx={{ pt: "30vh", display: "flex", justifyContent: "center" }}>
          {data.userBrandRegistrationForm == null ? (
            <Typography>Registration link is wrong or expired. Get the new one</Typography>
          ) : (
            <form
              onSubmit={(e) => {
                const wrappedOnSubmit = handleSubmit(onSubmit);
                void wrappedOnSubmit(e);
              }}
            >
              <Stack gap={1}>
                <Typography>
                  Registration a user for
                  <Typography fontWeight={700} component="span">
                    {" "}
                    &quot;{data.userBrandRegistrationForm.brand.name}&quot;{" "}
                  </Typography>
                  brand.
                </Typography>

                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  error={errors.email != null}
                  helperText={errors.email != null && "Email is required"}
                  aria-errormessage="email is required"
                  inputProps={{ ...register("email", { required: true }) }}
                  type="email"
                />

                <Button variant="contained" type="submit" disabled={mutationLoading || emailSent}>
                  <Stack direction="row" gap={1}>
                    Create user
                    {mutationLoading && <CircularProgress size={20} />}
                  </Stack>
                </Button>

                {emailSent && (
                  <Alert severity="success">
                    We have emailed a confirmation link. Please check your mailbox.
                  </Alert>
                )}
              </Stack>
            </form>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Registration;
