import useSearchParam from "@hooks/useSearchParam";
import { nodes } from "@services/relay/utils";
import {
  AdvancedMarker,
  Map,
  Pin,
  isLatLngLiteral,
  toLatLngLiteral,
} from "@vis.gl/react-google-maps";
import { useEffect, useMemo, useState, type ComponentProps, type FC } from "react";
import { graphql, useFragment } from "react-relay";

import { type LocationsMap_brand$key } from "./__generated__/LocationsMap_brand.graphql";

type Props = {
  brandRef: LocationsMap_brand$key;
};

const SAN_FRANCISCO = { lng: -122.44925982279332, lat: 37.75734091158713 };

const brandFragment = graphql`
  fragment LocationsMap_brand on BrandType {
    id
    locations {
      edges {
        node {
          id
          address
          point {
            coordinates
          }
        }
      }
    }
  }
`;

const LocationsMap: FC<Props> = ({ brandRef }) => {
  const brand = useFragment(brandFragment, brandRef);
  const [locationId, setLocationId] = useSearchParam("locationId");
  const [rawPoint, setRawPoint] = useSearchParam("rawPoint");
  const [rawPointParsed, setRawPointParsed] = useState<google.maps.LatLngLiteral | null>(null);

  const locations = useMemo(() => nodes(brand.locations), [brand.locations]);

  useEffect(() => {
    if (rawPoint === null) {
      setRawPointParsed(null);
      return;
    }

    let jsonPoint: unknown;
    try {
      jsonPoint = JSON.parse(rawPoint);
    } catch (error) {
      setRawPoint(null);
      return;
    }

    if (isLatLngLiteral(jsonPoint)) {
      setRawPointParsed(toLatLngLiteral(jsonPoint));
    }
  }, [rawPoint, setRawPoint]);

  useEffect(() => {
    if (rawPoint === null && locationId === null && locations.length > 0) {
      setLocationId(locations[0].id);
    }
  }, [locationId, locations, setLocationId, rawPoint]);

  const center: ComponentProps<typeof Map>["center"] = useMemo(() => {
    if (rawPointParsed !== null) {
      return rawPointParsed;
    } else if (locationId !== null) {
      const selectedLocation = locations.find((location) => location.id === locationId);
      if (selectedLocation !== undefined) {
        return {
          lng: selectedLocation.point.coordinates[0],
          lat: selectedLocation.point.coordinates[1],
        };
      }
    }
    return SAN_FRANCISCO;
  }, [locationId, locations, rawPointParsed]);

  return (
    <Map
      mapId="brand-locations-map"
      defaultZoom={12}
      defaultCenter={SAN_FRANCISCO}
      center={center}
      gestureHandling="none"
      zoomControl
      disableDefaultUI
    >
      {locations.map((location) => (
        <AdvancedMarker
          key={location.id}
          position={{
            lng: location.point.coordinates[0],
            lat: location.point.coordinates[1],
          }}
        />
      ))}
      {rawPointParsed !== null && (
        <AdvancedMarker position={rawPointParsed}>
          <Pin background={"#f5c300"} borderColor={"#cc9900"} glyphColor={"#ffd966"} />
        </AdvancedMarker>
      )}
    </Map>
  );
};

export default LocationsMap;
