import useSearchParam from "@hooks/useSearchParam";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import parse from "autosuggest-highlight/parse";
import { useEffect, useMemo, useState, type FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { type BrandLocationInputs } from "./LocationsListItemEdit";

const LocationSelect: FC = () => {
  const { control, watch, register, setValue, resetField } = useFormContext<BrandLocationInputs>();
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [inputValue, setInputValue] = useState("");
  const map = useMap();
  const places = useMapsLibrary("places");
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const [sessionToken, setSessionToken] = useState<
    google.maps.places.AutocompleteSessionToken | undefined
  >();
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  const [, setLocationId] = useSearchParam("locationId");
  const [, setRawPoint] = useSearchParam("rawPoint");
  const value = watch("place");

  useEffect(() => {
    register("coords", { required: true });
  }, [register]);

  useEffect(() => {
    if (places == null || map == null) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => {
      setAutocompleteService(null);
      setPlacesService(null);
    };
  }, [map, places]);

  const fetch = useMemo(
    () =>
      debounce(
        (
          input: string,
          callback: (predictions: google.maps.places.AutocompletePrediction[] | null) => void
        ) => {
          if (autocompleteService == null) {
            callback(null);
            return;
          }
          const request = { input, sessionToken };
          void autocompleteService.getPlacePredictions(request, callback);
        },
        400
      ),
    [autocompleteService, sessionToken]
  );

  useEffect(() => {
    if (value == null || placesService === null) return;

    placesService.getDetails(
      {
        placeId: value.place_id,
        fields: ["geometry"],
        sessionToken,
      },
      (placeDetails) => {
        const location = placeDetails?.geometry?.location;

        if (location == null) {
          console.error("Unexpected place details response", placeDetails);
          return;
        }

        const locationJSON = location.toJSON();
        setRawPoint(JSON.stringify(locationJSON));
        setLocationId(null);
        setValue("coords", [locationJSON.lng, locationJSON.lat]);
      }
    );
  }, [value, placesService, sessionToken, setRawPoint, setLocationId, setValue]);

  useEffect(() => {
    let active = true;

    if (autocompleteService == null) {
      return;
    }

    if (inputValue === "") {
      setOptions(value != null ? [value] : []);
      return;
    }

    fetch(inputValue, (predictions) => {
      if (!active) return;

      const newOptions: google.maps.places.AutocompletePrediction[] = predictions ?? [];

      if (value != null && newOptions.filter((o) => o.place_id === value.place_id).length === 0) {
        newOptions.unshift(value);
      }

      setOptions(newOptions);
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, autocompleteService]);

  return (
    <Controller
      name="place"
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <Autocomplete
          size="small"
          fullWidth
          getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={field.value}
          isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
          noOptionsText="No locations"
          onChange={(_, newValue) => {
            if (newValue !== null && !options.includes(newValue)) {
              setOptions([newValue, ...options]);
            }

            resetField("coords");
            field.onChange(newValue);
          }}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              label="Location address"
              fullWidth
              error={fieldState.error != null}
            />
          )}
          renderOption={(props, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;

            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length])
            );

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    <LocationOnIcon sx={{ color: "text.secondary" }} />
                  </Grid>
                  <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                      >
                        {part.text}
                      </Box>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    />
  );
};

export default LocationSelect;
