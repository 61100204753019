import useSearchParam from "@hooks/useSearchParam";
import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { mutationErrorHandler } from "@services/relay/utils";
import { useConfirm } from "material-ui-confirm";
import { type FC } from "react";
import { graphql, useFragment, useMutation } from "react-relay";

import { type LocationsListItemDeleteButtonMutation } from "./__generated__/LocationsListItemDeleteButtonMutation.graphql";
import { type LocationsListItemDeleteButton_location$key } from "./__generated__/LocationsListItemDeleteButton_location.graphql";

type Props = {
  locationRef: LocationsListItemDeleteButton_location$key;
};

const locationFragment = graphql`
  fragment LocationsListItemDeleteButton_location on BrandLocationType {
    id
    address
  }
`;

const mutation = graphql`
  mutation LocationsListItemDeleteButtonMutation($locationId: ID!) {
    brandLocationDelete(input: { id: $locationId }) {
      brand {
        ...LocationsList_brand
      }
    }
  }
`;

const LocationsListItemDeleteButton: FC<Props> = ({ locationRef }) => {
  const [, setLocationId] = useSearchParam("locationId");
  const location = useFragment(locationFragment, locationRef);
  const [commitMutation, mutationLoading] =
    useMutation<LocationsListItemDeleteButtonMutation>(mutation);
  const confirm = useConfirm();

  const handleDelete = (): void => {
    commitMutation({
      variables: {
        locationId: location.id,
      },
      onCompleted: () => {
        setLocationId(null);
      },
      onError: mutationErrorHandler,
    });
  };

  const onDelete = (): void => {
    void confirm({
      description: `This will permanently delete location "${location.address}".`,
    })
      .then(handleDelete)
      .catch(() => null);
  };

  return (
    <IconButton onClick={onDelete} disabled={mutationLoading}>
      <Delete color={mutationLoading ? "disabled" : "error"} />
    </IconButton>
  );
};

export default LocationsListItemDeleteButton;
