/**
 * @generated SignedSource<<65e9b4c4f0b7d38aacd9d2aa43faa1ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocationsListItemEdit_location$data = {
  readonly address: string;
  readonly googlePlaceId: string;
  readonly id: string;
  readonly point: {
    readonly coordinates: ReadonlyArray<number>;
  };
  readonly " $fragmentType": "LocationsListItemEdit_location";
};
export type LocationsListItemEdit_location$key = {
  readonly " $data"?: LocationsListItemEdit_location$data;
  readonly " $fragmentSpreads": FragmentRefs<"LocationsListItemEdit_location">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LocationsListItemEdit_location",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "googlePlaceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeoJsonType",
      "kind": "LinkedField",
      "name": "point",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coordinates",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandLocationType",
  "abstractKey": null
};

(node as any).hash = "aaabccbd4e180cd7744cb2fcf2881e37";

export default node;
