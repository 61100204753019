import { Button, CircularProgress, Stack } from "@mui/material";
import { type FC } from "react";
import { graphql, useMutation } from "react-relay";

import { type LogoutButtonMutation } from "./__generated__/LogoutButtonMutation.graphql";

const mutation = graphql`
  mutation LogoutButtonMutation {
    logout(input: {}) {
      ok
    }
  }
`;

const LogoutButton: FC = () => {
  const [commitMutation, mutationLoading] = useMutation<LogoutButtonMutation>(mutation);

  const onLogout = (): void => {
    commitMutation({
      variables: {},
      onCompleted: () => {
        window.location.href = "/login";
      },
    });
  };

  return (
    <Button color="inherit" onClick={onLogout} disabled={mutationLoading}>
      <Stack direction="row" alignItems="center" gap={1}>
        Logout
        {mutationLoading && <CircularProgress size={15} sx={{ color: "white" }} />}
      </Stack>
    </Button>
  );
};

export default LogoutButton;
