import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

type Connection<T> =
  | {
      readonly edges: ReadonlyArray<
        | {
            readonly node: T | null | undefined;
          }
        | null
        | undefined
      >;
    }
  | null
  | undefined;

const nodes = <T>(connection: Connection<T>): T[] => {
  if (connection?.edges == null) return [];
  return connection.edges.map((e) => e?.node).filter((n) => n != null) as T[];
};

const mutationErrorHandler = (error: unknown): void => {
  if (error instanceof Error) {
    Sentry.captureException(error);
    toast.error(error.message, {
      position: "top-center",
      theme: "colored",
    });
  } else if (
    error != null &&
    typeof error === "object" &&
    "errors" in error &&
    Object.prototype.hasOwnProperty.call(error, "errors") &&
    Array.isArray(error.errors) &&
    error.errors.length > 0
  ) {
    const errors = error.errors as Array<{ message: string }>;
    errors.map((error) =>
      toast.error(error.message, {
        position: "top-center",
        theme: "colored",
      })
    );
  } else {
    // Maybe missed some case
    Sentry.captureMessage(JSON.stringify(error));
  }
};

export { mutationErrorHandler, nodes };
