import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import downloadMedia from "@services/downloadMedia";
import downloadZip from "@services/downloadZip";
import dayjs from "dayjs";
import { useRef, useState, type FC } from "react";
import { graphql, useFragment } from "react-relay";

import MediaListCardImage from "./MediaListCardImage";
import MediaListCardVideo from "./MediaListCardVideo";
import MediaListCarousel from "./MediaListCarousel";
import { type MediaListCard_instagramMedia$key } from "./__generated__/MediaListCard_instagramMedia.graphql";
import { CARD_HEIGHT } from "./constants";

type Props = {
  instagramMediaRef: MediaListCard_instagramMedia$key;
};

const instagramMediaFragment = graphql`
  fragment MediaListCard_instagramMedia on InstagramMediaType {
    id
    caption
    mediaId
    mediaUrl
    mediaType
    permalink
    publishedAt
    offerPeriodAcceptance {
      id
      offerPeriod {
        id
        shortCode
      }
    }
    medias {
      id
      mediaUrl
    }
    ...MediaListCardVideo_instagramMedia
    ...MediaListCardImage_instagramMedia
    ...MediaListCarousel_instagramMedia
  }
`;

const MediaListCard: FC<Props> = ({ instagramMediaRef }) => {
  const media = useFragment(instagramMediaFragment, instagramMediaRef);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [downloading, setDownloading] = useState<boolean>(false);

  const publishedAt = dayjs(media.publishedAt as string).format("MMMM D, YYYY - h:mmA");

  return (
    <Card sx={{ height: CARD_HEIGHT }}>
      {media.mediaType === "VIDEO" && <MediaListCardVideo instagramMediaRef={media} />}
      {media.mediaType === "IMAGE" && <MediaListCardImage instagramMediaRef={media} />}
      {media.mediaType === "CAROUSEL_ALBUM" && <MediaListCarousel instagramMediaRef={media} />}

      <CardContent>
        <Typography gutterBottom variant="h6">
          Offer: {media.offerPeriodAcceptance?.offerPeriod.shortCode}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Post caption: {media.caption}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Published: {publishedAt}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" component="a" href={media.permalink} target="_blank">
          Open on Instagram
        </Button>
        <Button
          size="small"
          onClick={() => {
            setDownloading(true);

            let task: Promise<void>;
            if (media.mediaType === "CAROUSEL_ALBUM") {
              const urls = media.medias.map((media) => media.mediaUrl);
              task = downloadZip({ postId: media.mediaId, urls, linkRef });
            } else {
              task = downloadMedia({ mediaUrl: media.mediaUrl, linkRef });
            }

            void task.finally(() => {
              setDownloading(false);
            });
          }}
          disabled={downloading}
        >
          <Stack direction="row" gap={1} alignItems="center">
            Download
            {downloading && <CircularProgress size={15} />}
          </Stack>
        </Button>
        <a ref={linkRef} hidden></a>
      </CardActions>
    </Card>
  );
};

export default MediaListCard;
