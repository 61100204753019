import { Box, Skeleton } from "@mui/material";
import { type FC } from "react";

import { CARD_HEIGHT, CARD_WIDTH } from "./constants";

const Loader: FC = () => {
  return (
    <Box
      m={5}
      gap={2}
      display={"flex"}
      flexWrap={"wrap"}
      flexDirection={"row"}
      justifyContent={"center"}
    >
      <Skeleton variant="rounded" height={CARD_HEIGHT} sx={{ width: CARD_WIDTH }} />
      <Skeleton variant="rounded" height={CARD_HEIGHT} sx={{ width: CARD_WIDTH }} />
      <Skeleton variant="rounded" height={CARD_HEIGHT} sx={{ width: CARD_WIDTH }} />
      <Skeleton variant="rounded" height={CARD_HEIGHT} sx={{ width: CARD_WIDTH }} />
      <Skeleton variant="rounded" height={CARD_HEIGHT} sx={{ width: CARD_WIDTH }} />
      <Skeleton variant="rounded" height={CARD_HEIGHT} sx={{ width: CARD_WIDTH }} />
      <Skeleton variant="rounded" height={CARD_HEIGHT} sx={{ width: CARD_WIDTH }} />
      <Skeleton variant="rounded" height={CARD_HEIGHT} sx={{ width: CARD_WIDTH }} />
    </Box>
  );
};

export default Loader;
