import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Login from "@routes/Login";
import LoginCallback from "@routes/LoginCallback";
import Registration from "@routes/Registration";
import RegistrationConfirm from "@routes/RegistrationConfirm";
import Layout from "@routes/layout/Layout";
import * as Sentry from "@sentry/react";
import RelayEnvironment from "@services/relay/RelayEnvironment";
import ErrorBoundary from "@shared/ErrorBoundary";
import ErrorUI from "@shared/ErrorUI";
import { APIProvider } from "@vis.gl/react-google-maps";
import { ConfirmProvider } from "material-ui-confirm";
import { type FC } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Router, Switch } from "wouter";

import "./app.css";

import "react-toastify/dist/ReactToastify.css";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN as string,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App: FC = () => {
  return (
    <ErrorBoundary fallback={(data) => <ErrorUI error={data.error} />}>
      <CssBaseline />
      <ToastContainer />

      <Router>
        <RelayEnvironment>
          <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string} region="US">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ConfirmProvider>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/login/callback" component={LoginCallback} />
                  <Route path="/registration/:id" component={Registration} />
                  <Route path="/registration/:id/callback" component={RegistrationConfirm} />
                  <Route path="/" component={Layout} nest />
                </Switch>
              </ConfirmProvider>
            </LocalizationProvider>
          </APIProvider>
        </RelayEnvironment>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
