import type * as React from "react";
import { RelayEnvironmentProvider } from "relay-hooks";

import { createEnvironment } from "./environment";

const environment = createEnvironment();

export default function RelayEnvironment({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return <RelayEnvironmentProvider environment={environment}>{children}</RelayEnvironmentProvider>;
}
