/**
 * @generated SignedSource<<9b404777776f7aa99be757f2e16269ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingQuery$variables = Record<PropertyKey, never>;
export type BillingQuery$data = {
  readonly viewerBrand: {
    readonly subscription: {
      readonly id: string;
      readonly isActive: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"Billing_subscription">;
    } | null | undefined;
  };
};
export type BillingQuery = {
  response: BillingQuery$data;
  variables: BillingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerBrand",
        "kind": "LinkedField",
        "name": "viewerBrand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StripeSubscriptionType",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Billing_subscription"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BillingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerBrand",
        "kind": "LinkedField",
        "name": "viewerBrand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StripeSubscriptionType",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trialEnd",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StripeProductType",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "spotsAvailable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offersAvailable",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bb52378e2a49223226b8ef6e8214e220",
    "id": null,
    "metadata": {},
    "name": "BillingQuery",
    "operationKind": "query",
    "text": "query BillingQuery {\n  viewerBrand {\n    subscription {\n      id\n      isActive\n      ...Billing_subscription\n    }\n    id\n  }\n}\n\nfragment Billing_subscription on StripeSubscriptionType {\n  id\n  trialEnd\n  product {\n    name\n    spotsAvailable\n    offersAvailable\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "006ca71dbbe2cf57b6df1c960ae01c64";

export default node;
