/**
 * @generated SignedSource<<cb17899341264e786a8fe19c41e747a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocationsListItemDisplay_location$data = {
  readonly address: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"LocationsListItemDeleteButton_location">;
  readonly " $fragmentType": "LocationsListItemDisplay_location";
};
export type LocationsListItemDisplay_location$key = {
  readonly " $data"?: LocationsListItemDisplay_location$data;
  readonly " $fragmentSpreads": FragmentRefs<"LocationsListItemDisplay_location">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LocationsListItemDisplay_location",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LocationsListItemDeleteButton_location"
    }
  ],
  "type": "BrandLocationType",
  "abstractKey": null
};

(node as any).hash = "f73c7b07a0253a86d18a2caf0b825229";

export default node;
