import { CardMedia } from "@mui/material";
import { type FC } from "react";
import { graphql, useFragment } from "react-relay";

import { type MediaListCardImage_instagramMedia$key } from "./__generated__/MediaListCardImage_instagramMedia.graphql";
import { CARD_MEDIA_HEIGHT, CARD_WIDTH } from "./constants";

type Props = {
  instagramMediaRef: MediaListCardImage_instagramMedia$key;
};

const instagramMediaFragment = graphql`
  fragment MediaListCardImage_instagramMedia on InstagramMediaType {
    id
    mediaUrl
  }
`;

const MediaListCardImage: FC<Props> = ({ instagramMediaRef }) => {
  const media = useFragment(instagramMediaFragment, instagramMediaRef);

  return (
    <CardMedia
      height={CARD_MEDIA_HEIGHT}
      sx={{ width: CARD_WIDTH }}
      src={media.mediaUrl ?? undefined}
      component="img"
    />
  );
};

export default MediaListCardImage;
