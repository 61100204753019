import useSearchParam from "@hooks/useSearchParam";
import { useState, type FC } from "react";
import { graphql, useFragment } from "react-relay";

import LocationsListItemDisplay from "./LocationsListItemDisplay";
import LocationsListItemEdit from "./LocationsListItemEdit";
import { type LocationsListItem_location$key } from "./__generated__/LocationsListItem_location.graphql";

type Props = {
  locationRef: LocationsListItem_location$key;
};

const locationFragment = graphql`
  fragment LocationsListItem_location on BrandLocationType {
    id
    ...LocationsListItemDisplay_location
    ...LocationsListItemEdit_location
  }
`;

const LocationsListItem: FC<Props> = ({ locationRef }) => {
  const location = useFragment(locationFragment, locationRef);
  const [, setLocationId] = useSearchParam("locationId");
  const [, setRawPoint] = useSearchParam("rawPoint");
  const [isEditing, setIsEditing] = useState(false);

  const startEdit = (): void => {
    setIsEditing(true);
  };

  const finishEdit = (): void => {
    setLocationId(location.id);
    setRawPoint(null);
    setIsEditing(false);
  };

  return isEditing ? (
    <LocationsListItemEdit locationRef={location} finishEdit={finishEdit} />
  ) : (
    <LocationsListItemDisplay locationRef={location} startEdit={startEdit} />
  );
};

export default LocationsListItem;
