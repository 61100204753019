import _ from "lodash";
import { type RefObject } from "react";

type Props = {
  mediaUrl: string | null | undefined;
  linkRef: RefObject<HTMLAnchorElement>;
};

const downloadMedia = async ({ mediaUrl, linkRef }: Props): Promise<void> => {
  if (mediaUrl == null || linkRef.current == null) return;

  const fileName = _.last(new URL(mediaUrl).pathname.split("/")) ?? mediaUrl;

  const response = await fetch(mediaUrl);
  const blob = await response.blob();
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    if (linkRef.current == null) {
      return;
    }

    const elem = linkRef.current;
    elem.download = fileName;
    if (typeof reader.result === "string") {
      elem.href = reader.result;
    }
    elem.click();
  };
};

export default downloadMedia;
