/**
 * @generated SignedSource<<a0142aa21b81f0a3de0b39bc962cc90a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OfferFilterQuery$variables = Record<PropertyKey, never>;
export type OfferFilterQuery$data = {
  readonly viewerBrand: {
    readonly offerChoices: ReadonlyArray<{
      readonly id: string;
      readonly internalName: string;
    }>;
  };
};
export type OfferFilterQuery = {
  response: OfferFilterQuery$data;
  variables: OfferFilterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "OfferChoice",
  "kind": "LinkedField",
  "name": "offerChoices",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OfferFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerBrand",
        "kind": "LinkedField",
        "name": "viewerBrand",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OfferFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerBrand",
        "kind": "LinkedField",
        "name": "viewerBrand",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f11550dac526759ca9ea2a8b9426b928",
    "id": null,
    "metadata": {},
    "name": "OfferFilterQuery",
    "operationKind": "query",
    "text": "query OfferFilterQuery {\n  viewerBrand {\n    offerChoices {\n      id\n      internalName\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "375799451242c75ae731c80dfd09fdba";

export default node;
