/**
 * @generated SignedSource<<e215fcbbb37001048bf4d4eea0c3e4b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BillingCustomerPortalMutation$variables = Record<PropertyKey, never>;
export type BillingCustomerPortalMutation$data = {
  readonly createCustomerPortalSession: {
    readonly sessionUrl: string;
  } | null | undefined;
};
export type BillingCustomerPortalMutation = {
  response: BillingCustomerPortalMutation$data;
  variables: BillingCustomerPortalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "CreateCustomerPortalSessionPayload",
    "kind": "LinkedField",
    "name": "createCustomerPortalSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sessionUrl",
        "storageKey": null
      }
    ],
    "storageKey": "createCustomerPortalSession(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingCustomerPortalMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BillingCustomerPortalMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "750633454ce1d5ba2c3cbb533226e89f",
    "id": null,
    "metadata": {},
    "name": "BillingCustomerPortalMutation",
    "operationKind": "mutation",
    "text": "mutation BillingCustomerPortalMutation {\n  createCustomerPortalSession(input: {}) {\n    sessionUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b41ada98e353059415529cdee6e2e38";

export default node;
