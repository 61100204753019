/**
 * @generated SignedSource<<2d975a2116dcae2c3c79788f30495122>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TehlInstagramMediaMediaTypeChoices = "CAROUSEL_ALBUM" | "IMAGE" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MediaListCard_instagramMedia$data = {
  readonly caption: string;
  readonly id: string;
  readonly mediaId: string;
  readonly mediaType: TehlInstagramMediaMediaTypeChoices;
  readonly mediaUrl: string | null | undefined;
  readonly medias: ReadonlyArray<{
    readonly id: string;
    readonly mediaUrl: string | null | undefined;
  }>;
  readonly offerPeriodAcceptance: {
    readonly id: string;
    readonly offerPeriod: {
      readonly id: string;
      readonly shortCode: string;
    };
  } | null | undefined;
  readonly permalink: string;
  readonly publishedAt: any;
  readonly " $fragmentSpreads": FragmentRefs<"MediaListCardImage_instagramMedia" | "MediaListCardVideo_instagramMedia" | "MediaListCarousel_instagramMedia">;
  readonly " $fragmentType": "MediaListCard_instagramMedia";
};
export type MediaListCard_instagramMedia$key = {
  readonly " $data"?: MediaListCard_instagramMedia$data;
  readonly " $fragmentSpreads": FragmentRefs<"MediaListCard_instagramMedia">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MediaListCard_instagramMedia",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permalink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OfferPeriodAcceptanceType",
      "kind": "LinkedField",
      "name": "offerPeriodAcceptance",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OfferPeriodType",
          "kind": "LinkedField",
          "name": "offerPeriod",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shortCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InstagramMediaType",
      "kind": "LinkedField",
      "name": "medias",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MediaListCardVideo_instagramMedia"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MediaListCardImage_instagramMedia"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MediaListCarousel_instagramMedia"
    }
  ],
  "type": "InstagramMediaType",
  "abstractKey": null
};
})();

(node as any).hash = "eab8465564d5add55d25a473421bd406";

export default node;
