import logoWhiteUrl from "@assets/logo-white.png";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import * as React from "react";
import { type FC } from "react";
import { Link } from "wouter";

import LogoutButton from "./LogoutButton";

const routes: Array<{ path: string; label: string }> = [
  { path: "/settings", label: "Settings" },
  { path: "/content-library", label: "Content Library" },
  { path: "/billing", label: "Billing" },
];

const TopBar: FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Box px={{ xs: 1, md: 3 }} py={{ xs: 1, md: 2 }} display="flex" alignItems="center">
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            id="menu-button"
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
            MenuListProps={{
              "aria-labelledby": "menu-button",
            }}
          >
            {routes.map((route) => (
              <MenuItem key={route.path} onClick={handleCloseNavMenu}>
                <Link to={route.path} asChild>
                  <Typography>{route.label}</Typography>
                </Link>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <img src={logoWhiteUrl} alt="logo" height={33} width={160} />

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, marginLeft: 4 }}>
          {routes.map((route) => (
            <Link key={route.path} to={route.path} asChild>
              <Button sx={{ color: "white" }}>{route.label}</Button>
            </Link>
          ))}
        </Box>

        <Box flexGrow={1} flex={1} justifyContent="flex-end" display="flex">
          <LogoutButton />
        </Box>
      </Box>
    </AppBar>
  );
};

export default TopBar;
