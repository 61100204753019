import { Box } from "@mui/material";
import { type FC } from "react";

import OfferFilter from "./OfferFilter";
import Ordering from "./Ordering";
import PublishedSince from "./PublishedSince";
import PublishedUntil from "./PublishedUntil";

const Filters: FC = () => {
  return (
    <Box
      my={2}
      gap={2}
      px={{ xs: 2, md: 0 }}
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent="center"
    >
      <Ordering />
      <OfferFilter />
      <PublishedSince />
      <PublishedUntil />
    </Box>
  );
};

export default Filters;
