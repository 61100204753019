/**
 * @generated SignedSource<<9e5dda37fcd07d8746057e30ea6c60ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Billing_subscription$data = {
  readonly id: string;
  readonly product: {
    readonly name: string;
    readonly offersAvailable: number;
    readonly spotsAvailable: number;
  };
  readonly trialEnd: any | null | undefined;
  readonly " $fragmentType": "Billing_subscription";
};
export type Billing_subscription$key = {
  readonly " $data"?: Billing_subscription$data;
  readonly " $fragmentSpreads": FragmentRefs<"Billing_subscription">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Billing_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trialEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StripeProductType",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "spotsAvailable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "offersAvailable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StripeSubscriptionType",
  "abstractKey": null
};

(node as any).hash = "779b23c30677ab647ce6e0f734f93555";

export default node;
