import { Box, Tab, Tabs } from "@mui/material";
import { Suspense, useEffect, type FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { Link, Route, Switch, useLocation } from "wouter";

import BrandInfo from "./BrandInfo/BrandInfo";
import BrandLocations from "./BrandLocations/BrandLocations";
import Loader from "./Loader";
import Logo from "./Logo/Logo";
import { type SettingsQuery } from "./__generated__/SettingsQuery.graphql";

const query = graphql`
  query SettingsQuery {
    viewerBrand {
      brand {
        id
        ...BrandLocations_brand
        ...Logo_brand
      }
      ...BrandInfo_viewer
    }
  }
`;

const Settings: FC = () => {
  const data = useLazyLoadQuery<SettingsQuery>(query, {}, { fetchPolicy: "store-and-network" });
  const [location, setLocation] = useLocation();

  useEffect(() => {
    if (location === "/") {
      setLocation("/brand");
    }
  }, [location, setLocation]);

  if (location === "/") {
    return null;
  }

  return (
    <Box p={2} display="flex" flexDirection="column">
      <Box mb={5}>
        <Tabs value={location} centered>
          <Tab label="Brand" value="/brand" to="/brand" component={Link} />
          <Tab label="Locations" value="/locations" to="/locations" component={Link} />
          <Tab label="Logo" value="/logo" to="/logo" component={Link} />
        </Tabs>
      </Box>

      <Switch>
        <Route path="/brand">
          <BrandInfo viewerRef={data.viewerBrand} />
        </Route>
        <Route path="/locations">
          <BrandLocations brandRef={data.viewerBrand.brand} />
        </Route>
        <Route path="/logo">
          <Logo brandRef={data.viewerBrand.brand} />
        </Route>
      </Switch>
    </Box>
  );
};

const wrappedLayout: FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Settings />
    </Suspense>
  );
};

export default wrappedLayout;
