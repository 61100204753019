import useSearchParam from "@hooks/useSearchParam";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from "@mui/material";
import { useEffect, type FC } from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { type OfferFilterQuery } from "./__generated__/OfferFilterQuery.graphql";

const query = graphql`
  query OfferFilterQuery {
    viewerBrand {
      offerChoices {
        id
        internalName
      }
    }
  }
`;

const OfferFilter: FC = () => {
  const { data, isLoading } = useQuery<OfferFilterQuery>(query);
  const [offer, setOffer] = useSearchParam("offer");

  const options = data?.viewerBrand.offerChoices ?? [];

  useEffect(() => {
    const options = data?.viewerBrand.offerChoices ?? [];
    if (options.length > 0 && offer !== null && !options.some((option) => option.id === offer)) {
      setOffer(null);
    }
  }, [offer, setOffer, data]);

  const handleChange = (event: SelectChangeEvent): void => {
    setOffer(event.target.value);
  };

  return (
    <Box width={{ md: 300 }}>
      <FormControl fullWidth>
        <InputLabel id="offer-select-label">Offer</InputLabel>
        <Select
          labelId="offer-select-label"
          id="offer-select"
          value={isLoading ? "loader" : offer ?? ""}
          label="Offer"
          onChange={handleChange}
          disabled={isLoading}
        >
          {isLoading && (
            <MenuItem value="loader">
              <CircularProgress size={16} />
            </MenuItem>
          )}
          <MenuItem value="">All</MenuItem>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.internalName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default OfferFilter;
