import useSearchParam from "@hooks/useSearchParam";
import { Box } from "@mui/material";
import { type FC } from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import Filters from "./Filters";
import Loader from "./Loader";
import MediaList from "./MediaList";
import { type ContentLibraryQuery } from "./__generated__/ContentLibraryQuery.graphql";

const query = graphql`
  query ContentLibraryQuery(
    $ordering: String
    $offer: String
    $publishedSince: Date
    $publishedUntil: Date
  ) {
    viewerBrand {
      ...MediaListViewerBrandFragment
    }
  }
`;

const ContentLibrary: FC = () => {
  const [offer] = useSearchParam("offer");
  const [ordering] = useSearchParam("ordering");
  const [publishedSince] = useSearchParam("publishedSince");
  const [publishedUntil] = useSearchParam("publishedUntil");

  const { data } = useQuery<ContentLibraryQuery>(
    query,
    { offer, ordering, publishedSince, publishedUntil },
    {
      fetchPolicy: "store-and-network",
    }
  );

  return (
    <Box>
      <Filters />
      {data?.viewerBrand == null ? <Loader /> : <MediaList viewerBrandRef={data.viewerBrand} />}
    </Box>
  );
};

export default ContentLibrary;
