/**
 * @generated SignedSource<<3bc84868b506dc722952780fbf9af54c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeoJsonGeomType = "POINT" | "%future added value";
export type BrandLocationUpsertInput = {
  address: string;
  clientMutationId?: string | null | undefined;
  googlePlaceId: string;
  id?: string | null | undefined;
  point: GeoJsonTypeInput;
};
export type GeoJsonTypeInput = {
  coordinates: ReadonlyArray<number>;
  type: GeoJsonGeomType;
};
export type LocationsListItemEditMutation$variables = {
  input: BrandLocationUpsertInput;
};
export type LocationsListItemEditMutation$data = {
  readonly brandLocationUpsert: {
    readonly brand: {
      readonly " $fragmentSpreads": FragmentRefs<"LocationsList_brand">;
    };
  } | null | undefined;
};
export type LocationsListItemEditMutation = {
  response: LocationsListItemEditMutation$data;
  variables: LocationsListItemEditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocationsListItemEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandLocationUpsertPayload",
        "kind": "LinkedField",
        "name": "brandLocationUpsert",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandType",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LocationsList_brand"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocationsListItemEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandLocationUpsertPayload",
        "kind": "LinkedField",
        "name": "brandLocationUpsert",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandType",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandLocationTypeConnection",
                "kind": "LinkedField",
                "name": "locations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandLocationTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BrandLocationType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "googlePlaceId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GeoJsonType",
                            "kind": "LinkedField",
                            "name": "point",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "coordinates",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "adb4df67423c4d25b1c1623fd8235819",
    "id": null,
    "metadata": {},
    "name": "LocationsListItemEditMutation",
    "operationKind": "mutation",
    "text": "mutation LocationsListItemEditMutation(\n  $input: BrandLocationUpsertInput!\n) {\n  brandLocationUpsert(input: $input) {\n    brand {\n      ...LocationsList_brand\n      id\n    }\n  }\n}\n\nfragment LocationsListItemDeleteButton_location on BrandLocationType {\n  id\n  address\n}\n\nfragment LocationsListItemDisplay_location on BrandLocationType {\n  id\n  address\n  ...LocationsListItemDeleteButton_location\n}\n\nfragment LocationsListItemEdit_location on BrandLocationType {\n  id\n  address\n  googlePlaceId\n  point {\n    coordinates\n  }\n}\n\nfragment LocationsListItem_location on BrandLocationType {\n  id\n  ...LocationsListItemDisplay_location\n  ...LocationsListItemEdit_location\n}\n\nfragment LocationsList_brand on BrandType {\n  id\n  locations {\n    edges {\n      node {\n        id\n        ...LocationsListItem_location\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "572c5b44284fbe0fc8c11ca8f9a4f2e8";

export default node;
