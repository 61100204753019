import logoWhiteUrl from "@assets/logo-white.png";
import useSearchParam from "@hooks/useSearchParam";
import { AppBar, Box, CircularProgress, Container, Toolbar } from "@mui/material";
import { mutationErrorHandler } from "@services/relay/utils";
import { useEffect, type FC } from "react";
import { graphql, useMutation } from "react-relay";
import { useParams } from "wouter";
import { navigate } from "wouter/use-browser-location";

import { type RegistrationConfirmMutation } from "./__generated__/RegistrationConfirmMutation.graphql";

const mutation = graphql`
  mutation RegistrationConfirmMutation($input: UserBrandRegistrationConfirmInput!) {
    userBrandRegistrationConfirm(input: $input) {
      ok
    }
  }
`;

const RegistrationConfirm: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [approveCode] = useSearchParam("approveCode");
  const [commitMutation, mutationLoading] = useMutation<RegistrationConfirmMutation>(mutation);

  useEffect(() => {
    // Use timeout to prevent duplicated effect executions
    const timeoutId = setTimeout(() => {
      commitMutation({
        variables: { input: { formId: id, approveCode: approveCode ?? "" } },
        onCompleted: () => {
          navigate("/");
        },
        onError: mutationErrorHandler,
      });
    });

    return () => {
      clearTimeout(timeoutId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <AppBar position="static" sx={{ px: 2 }}>
        <Toolbar disableGutters>
          <img src={logoWhiteUrl} alt="logo" height={33} width={160} />
        </Toolbar>
      </AppBar>

      <Container>
        <Box sx={{ pt: "30vh", display: "flex", justifyContent: "center" }}>
          {mutationLoading && <CircularProgress size={20} />}
        </Box>
      </Container>
    </Box>
  );
};

export default RegistrationConfirm;
