import { Grid } from "@mui/material";
import { type FC } from "react";
import { graphql, useFragment } from "react-relay";

import LocationsList from "./LocationsList";
import LocationsMap from "./LocationsMap";
import { type BrandLocations_brand$key } from "./__generated__/BrandLocations_brand.graphql";

type Props = {
  brandRef: BrandLocations_brand$key;
};

const brandFragment = graphql`
  fragment BrandLocations_brand on BrandType {
    id
    ...LocationsList_brand
    ...LocationsMap_brand
  }
`;

const BrandLocations: FC<Props> = ({ brandRef }) => {
  const brand = useFragment(brandFragment, brandRef);

  return (
    <Grid container height="100%" direction={{ xs: "column-reverse", md: "row" }}>
      <Grid item xs={4}>
        <LocationsList brandRef={brand} />
      </Grid>
      <Grid item xs height="70%" minHeight={"500px"}>
        <LocationsMap brandRef={brand} />
      </Grid>
    </Grid>
  );
};

export default BrandLocations;
