import { Component } from "react";

type Props = {
  children: React.ReactNode;
  fallback: React.ReactNode | React.FC<{ error: Error }>;
};
type State = { error: Error | null };

class ErrorBoundary extends Component<Props, State> {
  state: { error: Error | null } = { error: null };

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  render(): React.ReactNode {
    const { children, fallback } = this.props;
    const { error } = this.state;
    if (error != null) {
      console.error(error);
      if (typeof fallback === "function") {
        return fallback({ error });
      }
      return fallback;
    }
    return children;
  }
}

export default ErrorBoundary;
