import { useCallback } from "react";
import { navigate, useLocationProperty } from "wouter/use-browser-location";

type Value = string | null;

const useSearchParam = (name: string): [Value, (newValue: Value) => void] => {
  const value: Value = useLocationProperty(() =>
    new URLSearchParams(window.location.search).get(name)
  );

  const setValue = useCallback(
    (newValue: Value) => {
      const params = new URLSearchParams(window.location.search);

      if (newValue === null) {
        params.delete(name);
      } else {
        params.set(name, newValue);
      }

      navigate("?" + params.toString());
    },
    [name]
  );

  return [value, setValue];
};

export default useSearchParam;
