/**
 * @generated SignedSource<<681d1cb9fc0feb64f2c244b388697d09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LogoutButtonMutation$variables = Record<PropertyKey, never>;
export type LogoutButtonMutation$data = {
  readonly logout: {
    readonly ok: boolean | null | undefined;
  } | null | undefined;
};
export type LogoutButtonMutation = {
  response: LogoutButtonMutation$data;
  variables: LogoutButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "LogoutPayload",
    "kind": "LinkedField",
    "name": "logout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": "logout(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LogoutButtonMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LogoutButtonMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "50a192e8aac479d185ca2524e8cb1c26",
    "id": null,
    "metadata": {},
    "name": "LogoutButtonMutation",
    "operationKind": "mutation",
    "text": "mutation LogoutButtonMutation {\n  logout(input: {}) {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "30e05d0f9c5cff0ee42c45c0eda6173e";

export default node;
