import JSZip from "jszip";
import _ from "lodash";
import { type RefObject } from "react";

type Props = {
  postId: string;
  urls: Array<string | null | undefined>;
  linkRef: RefObject<HTMLAnchorElement>;
};

const downloadZip = async ({ postId, urls, linkRef }: Props): Promise<void> => {
  if (linkRef.current == null) return;

  const zip = new JSZip();

  for (const url of urls) {
    if (url == null) continue;

    const response = await fetch(url);
    const blob = await response.blob();
    const fileName = _.last(new URL(url).pathname.split("/")) ?? url;
    zip.file(fileName, blob);
  }

  const zipData = await zip.generateAsync({
    type: "blob",
    streamFiles: true,
  });

  const elem = linkRef.current;
  elem.download = `${postId}.zip`;
  elem.href = window.URL.createObjectURL(zipData);
  elem.click();
};

export default downloadZip;
