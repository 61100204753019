import { Box } from "@mui/system";
import { useState, type FC } from "react";
import { graphql, useFragment } from "react-relay";

import EditBrandInfo from "./EditBrandInfo";
import ShowBrandInfo from "./ShowBrandInfo";
import { type BrandInfo_viewer$key } from "./__generated__/BrandInfo_viewer.graphql";

type Props = {
  viewerRef: BrandInfo_viewer$key;
};

const viewerFragment = graphql`
  fragment BrandInfo_viewer on ViewerBrand {
    brand {
      id
      ...ShowBrandInfo_brand
    }
    ...EditBrandInfo_viewer
  }
`;

const BrandInfo: FC<Props> = ({ viewerRef }) => {
  const viewer = useFragment(viewerFragment, viewerRef);
  const [edit, setEdit] = useState(false);

  return (
    <Box
      display="flex"
      justifyContent={{ xs: "flex-start", md: "center" }}
      width={{ xs: "100%", md: "400px" }}
      alignSelf={"center"}
    >
      {edit ? (
        <EditBrandInfo
          viewerRef={viewer}
          onClose={() => {
            setEdit(false);
          }}
        />
      ) : (
        <ShowBrandInfo
          brandRef={viewer.brand}
          onEdit={() => {
            setEdit(true);
          }}
        />
      )}
    </Box>
  );
};

export default BrandInfo;
