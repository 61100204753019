/**
 * @generated SignedSource<<eb3fd176baf0275b9c7b84538e802485>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShowBrandInfo_brand$data = {
  readonly businessPageGoogle: string | null | undefined;
  readonly businessPageYelp: string | null | undefined;
  readonly category: {
    readonly icon: string;
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly id: string;
  readonly igName: string | null | undefined;
  readonly name: string;
  readonly phone: string | null | undefined;
  readonly website: string | null | undefined;
  readonly " $fragmentType": "ShowBrandInfo_brand";
};
export type ShowBrandInfo_brand$key = {
  readonly " $data"?: ShowBrandInfo_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShowBrandInfo_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowBrandInfo_brand",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "igName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CategoryType",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessPageYelp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessPageGoogle",
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "670ebc6f3c119d6547b6507b77269b74";

export default node;
