/**
 * @generated SignedSource<<96bbf7a65c95da09261670aefe5eee32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrandUpdateInput = {
  businessPageGoogle: string;
  businessPageYelp: string;
  category: string;
  clientMutationId?: string | null | undefined;
  igName: string;
  name: string;
  phone: string;
  website: string;
};
export type EditBrandInfoMutation$variables = {
  input: BrandUpdateInput;
};
export type EditBrandInfoMutation$data = {
  readonly brandUpdate: {
    readonly brand: {
      readonly " $fragmentSpreads": FragmentRefs<"EditBrandInfo_brand">;
    } | null | undefined;
  } | null | undefined;
};
export type EditBrandInfoMutation = {
  response: EditBrandInfoMutation$data;
  variables: EditBrandInfoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditBrandInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandUpdatePayload",
        "kind": "LinkedField",
        "name": "brandUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandType",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditBrandInfo_brand"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditBrandInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandUpdatePayload",
        "kind": "LinkedField",
        "name": "brandUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandType",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "igName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CategoryType",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "businessPageYelp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "businessPageGoogle",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff8331e9d41763a10df6e42e63c718f2",
    "id": null,
    "metadata": {},
    "name": "EditBrandInfoMutation",
    "operationKind": "mutation",
    "text": "mutation EditBrandInfoMutation(\n  $input: BrandUpdateInput!\n) {\n  brandUpdate(input: $input) {\n    brand {\n      ...EditBrandInfo_brand\n      id\n    }\n  }\n}\n\nfragment EditBrandInfo_brand on BrandType {\n  id\n  name\n  igName\n  phone\n  website\n  category {\n    id\n    name\n  }\n  businessPageYelp\n  businessPageGoogle\n}\n"
  }
};
})();

(node as any).hash = "aa7bf20351c81c30de088b508463c693";

export default node;
