/**
 * @generated SignedSource<<4c642611de3f9aa0f91dd49b09dc7117>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TehlInstagramMediaMediaTypeChoices = "CAROUSEL_ALBUM" | "IMAGE" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MediaListCarousel_instagramMedia$data = {
  readonly id: string;
  readonly medias: ReadonlyArray<{
    readonly id: string;
    readonly mediaType: TehlInstagramMediaMediaTypeChoices;
    readonly " $fragmentSpreads": FragmentRefs<"MediaListCardImage_instagramMedia" | "MediaListCardVideo_instagramMedia">;
  }>;
  readonly " $fragmentType": "MediaListCarousel_instagramMedia";
};
export type MediaListCarousel_instagramMedia$key = {
  readonly " $data"?: MediaListCarousel_instagramMedia$data;
  readonly " $fragmentSpreads": FragmentRefs<"MediaListCarousel_instagramMedia">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MediaListCarousel_instagramMedia",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "InstagramMediaType",
      "kind": "LinkedField",
      "name": "medias",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MediaListCardVideo_instagramMedia"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MediaListCardImage_instagramMedia"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InstagramMediaType",
  "abstractKey": null
};
})();

(node as any).hash = "540781074ebd2817fceb60c237a87a0b";

export default node;
