/**
 * @generated SignedSource<<a4cbf379f2ae6a9cd488e6bd7784119e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MediaListViewerBrandFragment$data = {
  readonly brand: {
    readonly id: string;
    readonly instagramMedias: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"MediaListCard_instagramMedia">;
        } | null | undefined;
      } | null | undefined>;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "MediaListViewerBrandFragment";
};
export type MediaListViewerBrandFragment$key = {
  readonly " $data"?: MediaListViewerBrandFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MediaListViewerBrandFragment">;
};

import MediaListInstagramMediasPaginationQuery_graphql from './MediaListInstagramMediasPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "brand",
  "instagramMedias"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 15,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "offer"
    },
    {
      "kind": "RootArgument",
      "name": "ordering"
    },
    {
      "kind": "RootArgument",
      "name": "publishedSince"
    },
    {
      "kind": "RootArgument",
      "name": "publishedUntil"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": MediaListInstagramMediasPaginationQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "MediaListViewerBrandFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandType",
      "kind": "LinkedField",
      "name": "brand",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": "instagramMedias",
          "args": [
            {
              "kind": "Variable",
              "name": "offer",
              "variableName": "offer"
            },
            {
              "kind": "Variable",
              "name": "ordering",
              "variableName": "ordering"
            },
            {
              "kind": "Variable",
              "name": "publishedSince",
              "variableName": "publishedSince"
            },
            {
              "kind": "Variable",
              "name": "publishedUntil",
              "variableName": "publishedUntil"
            }
          ],
          "concreteType": "InstagramMediaTypeConnection",
          "kind": "LinkedField",
          "name": "__MediaListViewerBrandFragment_instagramMedias_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramMediaTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InstagramMediaType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "MediaListCard_instagramMedia"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "ViewerBrand",
  "abstractKey": null
};
})();

(node as any).hash = "ab59ea1c93654fbc56be8ecbb4124de5";

export default node;
