import useSearchParam from "@hooks/useSearchParam";
import { Edit } from "@mui/icons-material";
import { Button, IconButton, Stack } from "@mui/material";
import { useMap } from "@vis.gl/react-google-maps";
import { type FC } from "react";
import { graphql, useFragment } from "react-relay";

import LocationsListItemDeleteButton from "./LocationsListItemDeleteButton";
import { type LocationsListItemDisplay_location$key } from "./__generated__/LocationsListItemDisplay_location.graphql";

type Props = {
  locationRef: LocationsListItemDisplay_location$key;
  startEdit: () => void;
};

const locationFragment = graphql`
  fragment LocationsListItemDisplay_location on BrandLocationType {
    id
    address
    ...LocationsListItemDeleteButton_location
  }
`;

const LocationsListItemDisplay: FC<Props> = ({ locationRef, startEdit }) => {
  const location = useFragment(locationFragment, locationRef);
  const [locationId, setLocationId] = useSearchParam("locationId");
  const [, setRawPoint] = useSearchParam("rawPoint");
  const map = useMap("brand-locations-map");

  const onLocationClick = (clickedLocationId: string): void => {
    const el = map?.getDiv();

    if (el !== undefined) {
      window.scrollTo({
        top: el.getBoundingClientRect().top + window.scrollY - 10,
        behavior: "smooth",
      });
    }
    setLocationId(clickedLocationId);
    setRawPoint(null);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Button
        variant="text"
        sx={{ textAlign: "left" }}
        onClick={() => {
          onLocationClick(location.id);
        }}
        color={locationId === location.id ? "secondary" : "primary"}
      >
        {location.address}
      </Button>

      <Stack direction="row" spacing={1}>
        <IconButton onClick={startEdit}>
          <Edit />
        </IconButton>
        <LocationsListItemDeleteButton locationRef={location} />
      </Stack>
    </Stack>
  );
};

export default LocationsListItemDisplay;
