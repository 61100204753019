import { Box } from "@mui/material";
import Billing from "@routes/billing/Billing";
import ContentLibrary from "@routes/content_library/ContentLibrary";
import Settings from "@routes/settings/Settings";
import ErrorBoundary from "@shared/ErrorBoundary";
import ErrorUI from "@shared/ErrorUI";
import { Suspense, type FC } from "react";
import { Redirect, Route, Switch } from "wouter";

import TopBar from "./TopBar";

const Layout: FC = () => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TopBar />

      <ErrorBoundary fallback={(data) => <ErrorUI error={data.error} />}>
        <Switch>
          <Route path="/" component={() => <Redirect to="/content-library" />} />
          <Route path="/settings" component={Settings} nest />
          <Route path="/content-library" component={ContentLibrary} />
          <Route path="/billing" component={Billing} />
          <Route>404: App</Route>
        </Switch>
      </ErrorBoundary>
    </Box>
  );
};

const wrappedLayout: FC = () => {
  return (
    // TODO: Implement loading spinner
    <Suspense fallback="Loading...">
      <Layout />
    </Suspense>
  );
};

export default wrappedLayout;
