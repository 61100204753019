import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Box, CardMedia, CircularProgress } from "@mui/material";
import { useRef, useState, type FC } from "react";
import { graphql, useFragment } from "react-relay";

import { type MediaListCardVideo_instagramMedia$key } from "./__generated__/MediaListCardVideo_instagramMedia.graphql";
import { CARD_MEDIA_HEIGHT, CARD_WIDTH } from "./constants";

type Props = {
  instagramMediaRef: MediaListCardVideo_instagramMedia$key;
};

const instagramMediaFragment = graphql`
  fragment MediaListCardVideo_instagramMedia on InstagramMediaType {
    id
    mediaUrl
    thumbnailUrl
  }
`;

const MediaListCardVideo: FC<Props> = ({ instagramMediaRef }) => {
  const media = useFragment(instagramMediaFragment, instagramMediaRef);
  const ref = useRef<HTMLVideoElement>(null);

  const [playing, setPlaying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const onVideoClick = (): void => {
    const video = ref.current;

    if (video === null) return;

    if (video.paused) {
      void video.play();
    } else {
      video.pause();
    }
  };

  return (
    <Box position="relative">
      <CardMedia
        ref={ref}
        height={CARD_MEDIA_HEIGHT}
        sx={{ width: CARD_WIDTH }}
        src={media.mediaUrl ?? undefined}
        component="video"
        preload="none"
        poster={media.thumbnailUrl ?? undefined}
        playsInline
        onPlay={() => {
          setPlaying(true);
          if (!loaded) {
            setLoading(true);
          }
        }}
        onPause={() => {
          setPlaying(false);
        }}
        onLoadedData={() => {
          setLoaded(true);
          setLoading(false);
        }}
      />

      <Box
        position="absolute"
        top={0}
        left={0}
        height={CARD_MEDIA_HEIGHT}
        width={CARD_WIDTH}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ cursor: "pointer", opacity: { xs: 1, md: 0 }, ":hover": { opacity: 1 } }}
        onClick={onVideoClick}
      >
        {loading && <CircularProgress />}
        {!loading &&
          (playing ? (
            <PauseCircleIcon color="info" fontSize="large" />
          ) : (
            <PlayCircleIcon color="info" fontSize="large" />
          ))}
      </Box>
    </Box>
  );
};

export default MediaListCardVideo;
