/**
 * @generated SignedSource<<cd83f8dac058ba79e6a86897e044c60d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditBrandInfo_viewer$data = {
  readonly brand: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"EditBrandInfo_brand">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CategorySelect_viewer">;
  readonly " $fragmentType": "EditBrandInfo_viewer";
};
export type EditBrandInfo_viewer$key = {
  readonly " $data"?: EditBrandInfo_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditBrandInfo_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditBrandInfo_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandType",
      "kind": "LinkedField",
      "name": "brand",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditBrandInfo_brand"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CategorySelect_viewer"
    }
  ],
  "type": "ViewerBrand",
  "abstractKey": null
};

(node as any).hash = "8cc08cf7a54038b7f84814701d0f8724";

export default node;
