/**
 * @generated SignedSource<<494c145cd1d434391bb7d27b90e18b46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocationsListItemDeleteButtonMutation$variables = {
  locationId: string;
};
export type LocationsListItemDeleteButtonMutation$data = {
  readonly brandLocationDelete: {
    readonly brand: {
      readonly " $fragmentSpreads": FragmentRefs<"LocationsList_brand">;
    };
  } | null | undefined;
};
export type LocationsListItemDeleteButtonMutation = {
  response: LocationsListItemDeleteButtonMutation$data;
  variables: LocationsListItemDeleteButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locationId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "locationId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocationsListItemDeleteButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandLocationDeletePayload",
        "kind": "LinkedField",
        "name": "brandLocationDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandType",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LocationsList_brand"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocationsListItemDeleteButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandLocationDeletePayload",
        "kind": "LinkedField",
        "name": "brandLocationDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandType",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandLocationTypeConnection",
                "kind": "LinkedField",
                "name": "locations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandLocationTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BrandLocationType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "googlePlaceId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GeoJsonType",
                            "kind": "LinkedField",
                            "name": "point",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "coordinates",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4dc9b5f90a45c5eb667b64271bd8feab",
    "id": null,
    "metadata": {},
    "name": "LocationsListItemDeleteButtonMutation",
    "operationKind": "mutation",
    "text": "mutation LocationsListItemDeleteButtonMutation(\n  $locationId: ID!\n) {\n  brandLocationDelete(input: {id: $locationId}) {\n    brand {\n      ...LocationsList_brand\n      id\n    }\n  }\n}\n\nfragment LocationsListItemDeleteButton_location on BrandLocationType {\n  id\n  address\n}\n\nfragment LocationsListItemDisplay_location on BrandLocationType {\n  id\n  address\n  ...LocationsListItemDeleteButton_location\n}\n\nfragment LocationsListItemEdit_location on BrandLocationType {\n  id\n  address\n  googlePlaceId\n  point {\n    coordinates\n  }\n}\n\nfragment LocationsListItem_location on BrandLocationType {\n  id\n  ...LocationsListItemDisplay_location\n  ...LocationsListItemEdit_location\n}\n\nfragment LocationsList_brand on BrandType {\n  id\n  locations {\n    edges {\n      node {\n        id\n        ...LocationsListItem_location\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "91053d35bbec7c48cf6ed3791af7e36e";

export default node;
