import type { GraphQLResponse, IEnvironment, RequestParameters, Variables } from "relay-runtime";
import { Environment, Network, RecordSource, Store } from "relay-runtime";

type ServerResponse = {
  data: Record<string, unknown>;
  errors: ErrorsShape | undefined;
};
type ErrorsShape = Array<{ message: string }>;

const API_URL = import.meta.env.VITE_API_URL as string | null;

if (API_URL == null) throw Error("API_URL is not defined");

const maybeLoginRedirect = (errors: ErrorsShape): void => {
  const hasAuthError = Boolean(errors.filter((error) => error.message === "Unauthorized").length);

  if (hasAuthError) window.location.href = `/login/?next=${window.location.pathname}`;
};

const fetchFunction = async (
  params: RequestParameters,
  variables: Variables
): Promise<GraphQLResponse> => {
  const response = await fetch(API_URL, {
    method: "POST",
    headers: [["Content-Type", "application/json"]],
    credentials: "include",
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  });

  if (!response.ok) {
    throw new Error("Server error");
  }

  const data = (await response.json()) as ServerResponse;
  const errors = data.errors;

  if (errors != null) {
    maybeLoginRedirect(errors);

    if (params.operationKind === "mutation") throw data;
  }

  return data;
};

const createEnvironment = (): IEnvironment => {
  const network = Network.create(fetchFunction);
  const store = new Store(new RecordSource());
  return new Environment({ store, network });
};

export { createEnvironment };
