import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";
import { graphql, useFragment } from "react-relay";

import { type Schema } from "./EditBrandInfo";
import { type CategorySelect_viewer$key } from "./__generated__/CategorySelect_viewer.graphql";

type Props = {
  viewerRef: CategorySelect_viewer$key;
  control: Control<Schema>;
};

const viewerFragment = graphql`
  fragment CategorySelect_viewer on ViewerBrand {
    categories {
      id
      name
      icon
    }
  }
`;

const CategorySelect: FC<Props> = ({ viewerRef, control }) => {
  const viewer = useFragment(viewerFragment, viewerRef);
  const categories = viewer.categories;

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="category-label">Category</InputLabel>
      <Controller
        name="category"
        control={control}
        render={({ field }) => (
          <Select labelId="category-label" id="category" label="Category" {...field}>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                <Stack direction="row" gap={2} alignItems="center">
                  <img src={category.icon} />
                  {category.name}
                </Stack>
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default CategorySelect;
