import "@assets/devices.min.css";
import { Box, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { mutationErrorHandler } from "@services/relay/utils";
import { useEffect, useRef, useState, type FC } from "react";
import { graphql, useFragment, useMutation } from "react-relay";

import Device from "./Device";
import styles from "./Logo.module.css";
import {
  type LogoGetUrlMutation,
  type LogoGetUrlMutation$data,
} from "./__generated__/LogoGetUrlMutation.graphql";
import { type LogoUpdateMutation } from "./__generated__/LogoUpdateMutation.graphql";
import { type Logo_brand$key } from "./__generated__/Logo_brand.graphql";

type Props = {
  brandRef: Logo_brand$key;
};

const brandFragment = graphql`
  fragment Logo_brand on BrandType {
    id
    name
    logoImage {
      url
    }
  }
`;

const getUrlMutation = graphql`
  mutation LogoGetUrlMutation($input: BrandLogoGetUploadUrlInput!) {
    brandLogoGetUploadUrl(input: $input) {
      url
      fileName
    }
  }
`;

const logoUpdateMutation = graphql`
  mutation LogoUpdateMutation($input: BrandLogoUpdateInput!) {
    brandLogoUpdate(input: $input) {
      brand {
        ...Logo_brand
      }
    }
  }
`;

const FileInput: FC<{ setFile: React.Dispatch<React.SetStateAction<File | null>> }> = ({
  setFile,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = (): void => {
    inputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file == null) {
      setFile(null);
    } else {
      setFile(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={inputRef}
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Button variant="contained" onClick={handleButtonClick}>
        Change Logo
      </Button>
    </div>
  );
};

const Logo: FC<Props> = ({ brandRef }) => {
  const brand = useFragment(brandFragment, brandRef);
  const [commitGetUrlMutation, getUrlMutationLoading] =
    useMutation<LogoGetUrlMutation>(getUrlMutation);
  const [commitLogoUpdateMutation, LogoUpdateMutationLoading] =
    useMutation<LogoUpdateMutation>(logoUpdateMutation);

  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  useEffect(() => {
    if (file == null) {
      setFileUrl(brand.logoImage?.url ?? null);
    } else {
      setFileUrl(URL.createObjectURL(file));
    }
  }, [brand.logoImage?.url, file]);

  const onSubmit = (): void => {
    if (file == null) return;

    commitGetUrlMutation({
      variables: {
        input: {
          fileName: file.name,
        },
      },
      onCompleted: uploadFile,
      onError: mutationErrorHandler,
    });
  };

  const uploadFile = (response: LogoGetUrlMutation$data): void => {
    if (file == null || response.brandLogoGetUploadUrl == null) {
      return;
    }

    setFetchLoading(true);

    const url = response.brandLogoGetUploadUrl.url;
    const fileName = response.brandLogoGetUploadUrl.fileName;

    void fetch(url, {
      method: "PUT",
      body: file,
      headers: { "Content-Type": file.type },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to upload file");
        }
        applyChanges(fileName);
      })
      .finally(() => {
        setFetchLoading(false);
      });
  };

  const applyChanges = (fileName: string): void => {
    commitLogoUpdateMutation({
      variables: {
        input: { fileName },
      },
      onCompleted: () => {
        setFile(null);
      },
      onError: mutationErrorHandler,
    });
  };

  const isLoading = getUrlMutationLoading || fetchLoading || LogoUpdateMutationLoading;
  const disableSave = fileUrl === brand.logoImage?.url || isLoading;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <Stack direction="row" spacing={2}>
        <FileInput setFile={setFile} />
        <Button variant="outlined" disabled={disableSave} onClick={onSubmit}>
          <Stack direction="row" gap={1} display="flex" alignItems="center">
            Save
            {isLoading && <CircularProgress size={15} />}
          </Stack>
        </Button>
      </Stack>

      <Device>
        <Box mx={1} pt={4}>
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <img src={fileUrl ?? undefined} className={styles.logoHead} />
            <Typography mt={1} fontSize={18} fontWeight={400}>
              {brand.name}
            </Typography>
            <Typography mt={1} fontSize={36} fontWeight={700}>
              Offer name
            </Typography>
          </Box>

          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="8px"
            border="1px solid #DBDBDB"
            p="16px"
          >
            <Box flex={2} gap={1} display="flex" flexDirection="column">
              <Typography fontSize={21} fontWeight={700} lineHeight="normal">
                {brand.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Offer description
              </Typography>
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <img src={fileUrl ?? undefined} className={styles.logoCard} />
            </Box>
          </Box>
        </Box>
      </Device>
    </Box>
  );
};

export default Logo;
