/**
 * @generated SignedSource<<e167f70c2c708a7af4d31d46ddfee7f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsQuery$variables = Record<PropertyKey, never>;
export type SettingsQuery$data = {
  readonly viewerBrand: {
    readonly brand: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"BrandLocations_brand" | "Logo_brand">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"BrandInfo_viewer">;
  };
};
export type SettingsQuery = {
  response: SettingsQuery$data;
  variables: SettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "icon",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerBrand",
        "kind": "LinkedField",
        "name": "viewerBrand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandType",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BrandLocations_brand"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Logo_brand"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BrandInfo_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerBrand",
        "kind": "LinkedField",
        "name": "viewerBrand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandType",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandLocationTypeConnection",
                "kind": "LinkedField",
                "name": "locations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandLocationTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BrandLocationType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "googlePlaceId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GeoJsonType",
                            "kind": "LinkedField",
                            "name": "point",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "coordinates",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageType",
                "kind": "LinkedField",
                "name": "logoImage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "igName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CategoryType",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "businessPageYelp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "businessPageGoogle",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CategoryType",
            "kind": "LinkedField",
            "name": "categories",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d5b1482feff321ed040ab341f3dd6d6",
    "id": null,
    "metadata": {},
    "name": "SettingsQuery",
    "operationKind": "query",
    "text": "query SettingsQuery {\n  viewerBrand {\n    brand {\n      id\n      ...BrandLocations_brand\n      ...Logo_brand\n    }\n    ...BrandInfo_viewer\n    id\n  }\n}\n\nfragment BrandInfo_viewer on ViewerBrand {\n  brand {\n    id\n    ...ShowBrandInfo_brand\n  }\n  ...EditBrandInfo_viewer\n}\n\nfragment BrandLocations_brand on BrandType {\n  id\n  ...LocationsList_brand\n  ...LocationsMap_brand\n}\n\nfragment CategorySelect_viewer on ViewerBrand {\n  categories {\n    id\n    name\n    icon\n  }\n}\n\nfragment EditBrandInfo_brand on BrandType {\n  id\n  name\n  igName\n  phone\n  website\n  category {\n    id\n    name\n  }\n  businessPageYelp\n  businessPageGoogle\n}\n\nfragment EditBrandInfo_viewer on ViewerBrand {\n  brand {\n    id\n    ...EditBrandInfo_brand\n  }\n  ...CategorySelect_viewer\n}\n\nfragment LocationsListItemDeleteButton_location on BrandLocationType {\n  id\n  address\n}\n\nfragment LocationsListItemDisplay_location on BrandLocationType {\n  id\n  address\n  ...LocationsListItemDeleteButton_location\n}\n\nfragment LocationsListItemEdit_location on BrandLocationType {\n  id\n  address\n  googlePlaceId\n  point {\n    coordinates\n  }\n}\n\nfragment LocationsListItem_location on BrandLocationType {\n  id\n  ...LocationsListItemDisplay_location\n  ...LocationsListItemEdit_location\n}\n\nfragment LocationsList_brand on BrandType {\n  id\n  locations {\n    edges {\n      node {\n        id\n        ...LocationsListItem_location\n      }\n    }\n  }\n}\n\nfragment LocationsMap_brand on BrandType {\n  id\n  locations {\n    edges {\n      node {\n        id\n        address\n        point {\n          coordinates\n        }\n      }\n    }\n  }\n}\n\nfragment Logo_brand on BrandType {\n  id\n  name\n  logoImage {\n    url\n  }\n}\n\nfragment ShowBrandInfo_brand on BrandType {\n  id\n  name\n  igName\n  phone\n  website\n  category {\n    id\n    name\n    icon\n  }\n  businessPageYelp\n  businessPageGoogle\n}\n"
  }
};
})();

(node as any).hash = "bbd60641826d23defffda1ae15b9486a";

export default node;
