import { Container, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import pluralize from "@services/pluralize";
import dayjs from "dayjs";
import { Suspense, type FC } from "react";
import { graphql, useFragment, useLazyLoadQuery, useMutation } from "react-relay";

import { type BillingCustomerPortalMutation } from "./__generated__/BillingCustomerPortalMutation.graphql";
import { type BillingQuery } from "./__generated__/BillingQuery.graphql";
import { type Billing_subscription$key } from "./__generated__/Billing_subscription.graphql";

type SubscriptionCardProps = {
  subscriptionRef: Billing_subscription$key;
};

const query = graphql`
  query BillingQuery {
    viewerBrand {
      subscription {
        id
        isActive
        ...Billing_subscription
      }
    }
  }
`;

const subscriptionFragment = graphql`
  fragment Billing_subscription on StripeSubscriptionType {
    id
    trialEnd
    product {
      name
      spotsAvailable
      offersAvailable
    }
  }
`;

const mutation = graphql`
  mutation BillingCustomerPortalMutation {
    createCustomerPortalSession(input: {}) {
      sessionUrl
    }
  }
`;

const SubscriptionDetails: FC<SubscriptionCardProps> = ({ subscriptionRef }) => {
  const subscription = useFragment<Billing_subscription$key>(subscriptionFragment, subscriptionRef);
  const [commitMutation, mutationLoading] = useMutation<BillingCustomerPortalMutation>(mutation);

  const goToCustomerPortal = (): void => {
    commitMutation({
      variables: {},
      onCompleted: (data) => {
        if (data.createCustomerPortalSession?.sessionUrl != null) {
          window.location.href = data.createCustomerPortalSession.sessionUrl;
        }
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <>
      <Typography variant="h4">Your active plan: {subscription.product.name}</Typography>
      <Box sx={{ ml: 3 }}>
        <Typography variant="body1">
          • {subscription.product.spotsAvailable}{" "}
          {pluralize("spot", subscription.product.spotsAvailable)} monthly
        </Typography>
        <Typography variant="body1">
          • {subscription.product.offersAvailable}{" "}
          {pluralize("offer", subscription.product.offersAvailable)} monthly
        </Typography>
        <Typography variant="body1">• Media library</Typography>
        {subscription.trialEnd != null && (
          <Typography variant="body1">
            • Trial ends on {dayjs(subscription.trialEnd as string).format("MMMM D, YYYY")}
          </Typography>
        )}
        <Button
          sx={{ mt: 1 }}
          variant="outlined"
          onClick={goToCustomerPortal}
          disabled={mutationLoading}
        >
          Manage Subscription
        </Button>
      </Box>
    </>
  );
};

const Billing: FC = () => {
  const data = useLazyLoadQuery<BillingQuery>(query, {}, { fetchPolicy: "store-and-network" });
  const subscription = data.viewerBrand.subscription;

  return (
    <Container sx={{ my: 1 }}>
      <Box sx={{ justifyContent: "center" }}>
        <Box sx={{ mb: 4 }}>
          {subscription?.isActive === true ? (
            <SubscriptionDetails subscriptionRef={subscription} />
          ) : (
            <Typography variant="body1" sx={{ ml: 2 }}>
              You don&apos;t have an active plan.
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

const Loader: FC = () => {
  return (
    <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
      <Skeleton variant="rounded" width={610} height={250} />
    </Box>
  );
};

const withSuspense: FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Billing />
    </Suspense>
  );
};

export default withSuspense;
