import { Box, Skeleton } from "@mui/material";
import { nodes } from "@services/relay/utils";
import { forwardRef, type FC } from "react";
import { VirtuosoGrid, type GridComponents, type GridListProps } from "react-virtuoso";
import { graphql, usePaginationFragment } from "relay-hooks";

import MediaListCard from "./MediaListCard";
import { type ContentLibraryQuery } from "./__generated__/ContentLibraryQuery.graphql";
import { type MediaListViewerBrandFragment$key } from "./__generated__/MediaListViewerBrandFragment.graphql";
import { CARD_HEIGHT, CARD_WIDTH, CARD_WIDTH_DESKTOP, CARD_WIDTH_MOBILE } from "./constants";

type Props = {
  viewerBrandRef: MediaListViewerBrandFragment$key;
};

const viewerBrandFragment = graphql`
  fragment MediaListViewerBrandFragment on ViewerBrand
  @refetchable(queryName: "MediaListInstagramMediasPaginationQuery")
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int", defaultValue: 15 }) {
    brand {
      id
      instagramMedias(
        ordering: $ordering
        offer: $offer
        publishedSince: $publishedSince
        publishedUntil: $publishedUntil
        after: $cursor
        first: $count
      ) @connection(key: "MediaListViewerBrandFragment_instagramMedias") {
        edges {
          node {
            id
            ...MediaListCard_instagramMedia
          }
        }
      }
    }
  }
`;

const FETCH_COUNT = 15;

const MediaScrollableList: GridComponents["List"] = forwardRef(
  ({ style, children }: GridListProps, listRef) => {
    const gridTemplateColumns = {
      xs: `repeat(auto-fill, ${CARD_WIDTH_MOBILE.toString()}px)`,
      md: `repeat(auto-fill, ${CARD_WIDTH_DESKTOP.toString()}px)`,
    };

    return (
      <Box
        ref={listRef}
        style={style}
        display={"grid"}
        gridTemplateColumns={gridTemplateColumns}
        gridAutoRows={"auto"}
        gap={2}
        my={1}
        justifyContent="center"
        component="div"
      >
        {children}
      </Box>
    );
  }
);

MediaScrollableList.displayName = "MediaScrollableList";

const components = { List: MediaScrollableList };

const MediaList: FC<Props> = ({ viewerBrandRef }) => {
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    ContentLibraryQuery,
    MediaListViewerBrandFragment$key
  >(viewerBrandFragment, viewerBrandRef);

  const onEndReached = (): void => {
    if (hasNext && !isLoadingNext) {
      loadNext(FETCH_COUNT);
    }
  };

  const medias = nodes(data.brand.instagramMedias);

  return (
    <VirtuosoGrid
      useWindowScroll
      components={components}
      endReached={onEndReached}
      itemContent={(_, media) =>
        media !== null ? (
          <MediaListCard instagramMediaRef={media} />
        ) : (
          <Skeleton variant="rounded" height={CARD_HEIGHT} sx={{ width: CARD_WIDTH }} />
        )
      }
      data={isLoadingNext ? [...medias, null, null, null, null, null] : medias}
    />
  );
};

export default MediaList;
